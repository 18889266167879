/* eslint-disable no-lone-blocks */
import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../../styles/main-style.css";
import { useStyles } from "../../Styles/mui-styles";
import Button from "@material-ui/core/Button";
import { avResponseType } from "./ShowAutoclaves";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import SessionData from "../../../services/session-data";
export default function ViewAutoclavesCycles(props: any) {
  /*React Dialog*/
  const [cycleInfo, setCycleInfo] = React.useState(false);
  const [cycleData, setCycleData] = React.useState<any>([]);
  var propsData: avResponseType = props.articleInfo;
  const classes = useStyles();

  const sd = new SessionData();
  const API_URL = sd.getBaseURL();
  const getCycleInfo = async () => {
    await axios
      .get(API_URL + `web-register/getCycleTypes/${propsData.autoclave_id}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((result) => {
        setCycleData(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    {
      props.cycleInfo ? setCycleInfo(true) : setCycleInfo(false);
      getCycleInfo();
    }
  }, []);
  const handleClose = () => {
    setCycleInfo(false);
    props.parentCallback(false);
  };

  const showTheDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={cycleInfo}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {propsData.name}
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#eff9f0" }}>
            <DialogContentText>
              <div className="container">
                {cycleData.map((items) => (
                  <>
                    <h5 className="py-2 text-center">{items.cycle_name}</h5>
                    <div className="row justify-content-md-center">
                      <div className="col-md-3 col-sm-6">
                        <Paper
                          className={classes.paperback}
                          style={{ textAlign: "center" }}
                        >
                          <p className="fw-500">Temperature</p>
                          <p>{items.temprature_celcius}</p>
                        </Paper>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <Paper
                          className={classes.paperback}
                          style={{ textAlign: "center" }}
                        >
                          <p className="fw-500">Pressure</p>
                          <p>{items.pressure}</p>
                        </Paper>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <Paper
                          className={classes.paperback}
                          style={{ textAlign: "center" }}
                        >
                          <p className="fw-500">Duration</p>
                          <p>{items.duration}</p>
                        </Paper>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <Paper
                          className={classes.paperback}
                          style={{ textAlign: "center" }}
                        >
                          <p className="fw-500">Drying Duration</p>
                          <p>{items.drying_duration}</p>
                        </Paper>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#eff9f0" }}>
            <Button onClick={handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return <div>{cycleInfo ? showTheDialog() : ""}</div>;
}
