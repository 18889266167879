/* eslint-disable*/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
//
import { Link } from "react-router-dom";
// Material Libraries
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TypographyMi from "@mui/material/Typography";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link as RouterLink, useLocation } from "react-router-dom";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// Images for the Menu
import medicalIcon from "../../../images/medical-icon.png";
import dashboard from "../../../images/dashboard.png";
import articleKit from "../../../images/article-kit.png";
import useInstrument from "../../../images/use-instrument.png";
import searchIcon from "../../../images/search-icon.png";
import avImage from "../../../images/autoclave-icon.png";
import avLaods from "../../../images/autoclave-loads.png";
import pointer from "../../../images/location-pointer3.png";
import userLogo from "../../../images/user-logo.png";
import usedArticle from "../../../images/used-articles.png";
import cautionSign from "../../../images/caution.png";
import addPouchSign from "../../../images/pouch.png";
import autoclaveService from "../../../images/autoclave-service.png";
import failedReport from "../../../images/failed-bi.png";
import emailLogo from "../../../images/email-logo2.png";
import { Button } from "@material-ui/core";
// Components
import axios from "axios";
import SessionData from "../../../services/session-data";
import SelectAdminBranch from "../../Utils/SelectAdminBranch";
import SelectReader from "../../Utils/SelectReader";
export const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  logo: {
    color: "white",
    textDecoration: "none",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor: `#${theme.palette.primary}`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  active: {
    backgroundColor: theme.palette.action.selected,
  },
  logoColor: {
    backgroundColor: `#${theme.palette.primary}`,
  },
}));

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const isHome = false; // pathname === "/index";
  const [mobileOpen, setMobileOpen] = React.useState(false);

  // Code from header.tsx
  const [clientName, setClientName] = useState<String | null>("");
  const [name, setName] = useState<String>("");
  const [branch, setBranch] = useState<String>("");
  const [city, setCity] = useState<number>();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // To get the location of the path
  const location = useLocation();
  //So I go to phpmyadmin to check what
  //rfid tag ids
  //

  const checkUserLogin = () => {
    const sd = new SessionData();
    // sd.logOutStaff();
    sd.logOutClient();
  };

  const loginLogout = () => {
    if (localStorage.getItem("authToken") != null) {
      return localStorage.getItem("authToken")!.length <= 0 ? (
        <Link to="/main-login">
          <Button style={{ color: "white" }} onClick={() => checkUserLogin()}>
            Log In
          </Button>
        </Link>
      ) : (
        <Link to="/main-login">
          <Button style={{ color: "white" }} onClick={() => checkUserLogin()}>
            Log out <ExitToAppIcon />
          </Button>
        </Link>
      );
    } else {
      return (
        <Link to="/main-login">
          <Button style={{ color: "white" }} onClick={() => checkUserLogin()}>
            Log In
          </Button>
        </Link>
      );
    }
  };

  //get client info by RP
  useEffect(() => {
    getClientInfo();
  }, []);

  const getClientInfo = () => {
    const sd = new SessionData();
    axios
      .get(
        sd.getBaseURL() +
          "user/companyinfo?" +
          `u.email=${localStorage.getItem("emailId")}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 200) {
          return;
        }
        setClientName(localStorage.getItem("clientName"));
        setName(data.data[0]["name"]);
        setBranch(data.data[0]["branch_name"]);
        setCity(data.data[0]["city"]);
      })
      .catch(() => {});
  };

  /* Modifying the source code to use the react router pathname hook to set
  selected prop and to use the react router component prop */

  const drawer = (
    <div>
      <Divider />
      <List>
        <ListItem>
          <ListItemIcon>
            <Avatar alt="medical-user" src={medicalIcon} />
          </ListItemIcon>
          <ListItemText
            primary={
              <React.Fragment>
                <TypographyMi fontWeight="bold">{name}</TypographyMi>
              </React.Fragment>
            }
            secondary={
              <React.Fragment>
                <TypographyMi
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {branch}-{city}
                </TypographyMi>
              </React.Fragment>
            }
            style={{ fontSize: "10px" }}
          />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          component={RouterLink}
          selected={pathname === `/index}`}
          to={`/index`}
          button
          key="/index"
          className={location.pathname === "/index" ? classes.active : ""}
        >
          <ListItemIcon>
            <img className="imageIcon " src={dashboard} alt="" />
          </ListItemIcon>
          <ListItemText primary={"Dashboard".toUpperCase()} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          component={RouterLink}
          selected={pathname === `/printLabel}`}
          to={`/printLabel`}
          button
          key="/printLabel"
          className={location.pathname === "/printLabel" ? classes.active : ""}
        >
          <ListItemIcon>
            <img className="imageIcon" src={searchIcon} alt="" />
          </ListItemIcon>
          <ListItemText primary={"Print Label".toUpperCase()} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          component={RouterLink}
          selected={pathname === `/checkStatus}`}
          to={`/checkStatus`}
          button
          key="/checkStatus"
          className={location.pathname === "/checkStatus" ? classes.active : ""}
        >
          <ListItemIcon>
            <img className="imageIcon" src={searchIcon} alt="" />
          </ListItemIcon>
          <ListItemText primary={"Check Status".toUpperCase()} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          component={RouterLink}
          selected={pathname === `/use-instrument}`}
          to={`/use-instrument`}
          button
          key="/use-instrument"
          className={
            location.pathname === "/use-instrument" ? classes.active : ""
          }
        >
          <ListItemIcon>
            <img className="imageIcon" src={useInstrument} alt="" />
          </ListItemIcon>
          <ListItemText primary={"Use Instrument".toUpperCase()} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          component={RouterLink}
          selected={pathname === `/autoclaves}`}
          to={`/autoclaves`}
          button
          key="/autoclaves"
          className={location.pathname === "/autoclaves" ? classes.active : ""}
        >
          <ListItemIcon>
            <img className="imageIcon" src={avImage} alt="" />
          </ListItemIcon>
          <ListItemText primary={"autoclaves".toUpperCase()} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          component={RouterLink}
          selected={pathname === `/autoclave-process}`}
          to={`/autoclave-process`}
          button
          key="/autoclave-process"
          className={
            location.pathname === "/autoclave-process" ? classes.active : ""
          }
        >
          <ListItemIcon>
            <img className="imageIcon" src={avLaods} alt="" />
          </ListItemIcon>
          <ListItemText primary={"autoclave loads".toUpperCase()} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          component={RouterLink}
          selected={pathname === `/service-log}`}
          to={`/service-log`}
          button
          key="/service-log"
          className={location.pathname === "/service-log" ? classes.active : ""}
        >
          <ListItemIcon>
            <img className="imageIcon" src={autoclaveService} alt="" />
          </ListItemIcon>
          <ListItemText primary={"Autoclave Service Log".toUpperCase()} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          component={RouterLink}
          selected={pathname === `/used-articles}`}
          to={`/used-articles`}
          button
          key="/used-articles"
          className={
            location.pathname === "/used-articles" ? classes.active : ""
          }
        >
          <ListItemIcon>
            <img className="imageIcon" src={usedArticle} alt="" />
          </ListItemIcon>
          <ListItemText primary={"Used Articles".toUpperCase()} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          component={RouterLink}
          selected={pathname === `/register-article-set/register-kit}`}
          to={`/register-article-set/register-kit`}
          button
          key="/register-kit"
          className={
            location.pathname === "/register-article-set/register-kit"
              ? classes.active
              : ""
          }
        >
          <ListItemIcon>
            <img className="imageIcon" src={articleKit} alt="" />
          </ListItemIcon>
          <ListItemText primary={"Article Set".toUpperCase()} />
        </ListItem>
      </List>
      {localStorage.getItem("roleId") === "1" ||
      localStorage.getItem("roleId") === "2" ? (
        <>
          <Divider />
          <List>
            <ListItem
              component={RouterLink}
              selected={pathname === `/register-reader}`}
              to={`/register-reader`}
              button
              key="/register-reader"
              className={
                location.pathname === "/register-reader" ? classes.active : ""
              }
            >
              <ListItemIcon>
                <img className="imageIcon" src={pointer} alt="" />
              </ListItemIcon>
              <ListItemText primary={"Register Reader".toUpperCase()} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              component={RouterLink}
              selected={pathname === `/registerservice}`}
              to={`/registerservice`}
              button
              key="/registerservice"
              className={
                location.pathname === "/registerservice" ? classes.active : ""
              }
            >
              <ListItemIcon>
                <img className="imageIcon" src={cautionSign} alt="" />
              </ListItemIcon>
              <ListItemText primary={"Add service Reasons".toUpperCase()} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              component={RouterLink}
              selected={pathname === `/registerpouchitem}`}
              to={`/registerpouchitem`}
              button
              key="/registerpouchitem"
              className={
                location.pathname === "/registerpouchitem" ? classes.active : ""
              }
            >
              <ListItemIcon>
                <img className="imageIcon" src={addPouchSign} alt="" />
              </ListItemIcon>
              <ListItemText primary={"Register Pouch Items".toUpperCase()} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              component={RouterLink}
              selected={pathname === `/registeremail}`}
              to={`/registeremail`}
              button
              key="/registeremail"
              className={
                location.pathname === "/registeremail" ? classes.active : ""
              }
            >
              <ListItemIcon>
                <img className="imageIcon" src={emailLogo} alt="" />
              </ListItemIcon>
              <ListItemText primary={"Register Emails".toUpperCase()} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              component={RouterLink}
              selected={pathname === `/register-user}`}
              to={`/register-user`}
              button
              key="/register-user"
              className={
                location.pathname === "/register-user" ? classes.active : ""
              }
            >
              <ListItemIcon>
                <img className="imageIcon" src={userLogo} alt="" />
              </ListItemIcon>
              <ListItemText primary={"users".toUpperCase()} />
            </ListItem>
          </List>
        </>
      ) : (
        ""
      )}
      <Divider />
      <List>
        <ListItem
          component={RouterLink}
          selected={pathname === `/failed-bi-list}`}
          to={`/failed-bi-list`}
          button
          key="/failed-bi-list"
          className={
            location.pathname === "/failed-bi-list" ? classes.active : ""
          }
        >
          <ListItemIcon>
            <img className="imageIcon" src={failedReport} alt="" />
          </ListItemIcon>
          <ListItemText primary={"BI Callback".toUpperCase()} />
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div
        style={{
          height: "64px",
          backgroundPosition: "center",
          backgroundSize: "cover",
          filter: "contrast(75%)",
          position: "absolute",
          top: "0px",
          width: "100%",
          zIndex: -2,
        }}
      />
      <AppBar position="sticky" className={isHome ? "" : classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            // to={"/index"}
            // component={RouterLink}
            className={classes.logo}
          >
            Reprolog | {clientName?.toUpperCase()}
          </Typography>
          <div style={{ flexGrow: 1 }}></div>
          {localStorage.getItem("roleId") === "1" ? (
            <Button style={{ color: "white" }}>
              <SelectAdminBranch />
            </Button>
          ) : (
            ""
          )}
          {localStorage.getItem("roleId") === "1" ||
          localStorage.getItem("roleId") === "2" ? (
            <>
              {/* <Button style={{ color: "white" }}>
                <SelectReader />
              </Button> */}
              <Button
                className="nav-item dropdown"
                style={{ zIndex: 1, position: "relative" }}
              >
                <a
                  style={{ color: "white" }}
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Register
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdown"
                  style={{
                    zIndex: 100,
                    position: "fixed",
                    right: "auto",
                    bottom: "auto",
                    top: "auto",
                    left: "auto",
                  }}
                >
                  <Link to="/register-article" className="dropdown-item">
                    <p style={{ color: "black" }}>Register Article</p>
                  </Link>
                  <Link to="/register-article-set" className="dropdown-item">
                    <p style={{ color: "black" }}>Register Article Set</p>
                  </Link>
                  <Link to="/register-article-type" className="dropdown-item">
                    <p style={{ color: "black" }}>Register Article Type</p>
                  </Link>
                  <Link
                    to="/autoclaves/register-autoclave"
                    className="dropdown-item"
                  >
                    <p style={{ color: "black" }}>Register Autoclave</p>
                  </Link>
                  {localStorage.getItem("roleId") === "1" ? (
                    <Link to="/register-branch" className="dropdown-item">
                      <p style={{ color: "black" }}>Register Branch</p>
                    </Link>
                  ) : (
                    ""
                  )}
                  <Link to="/register-bilot" className="dropdown-item">
                    <p style={{ color: "black" }}>Register BI LOT</p>
                  </Link>
                  <Link to="/register-reader" className="dropdown-item">
                    <p style={{ color: "black" }}>Register Reader</p>
                  </Link>
                  <Link to="/register-user" className="dropdown-item">
                    <p style={{ color: "black" }}>Register User</p>
                  </Link>
                </div>
              </Button>
            </>
          ) : (
            ""
          )}
          <Button style={{ color: "white" }}>{loginLogout()}</Button>
        </Toolbar>
      </AppBar>
      {isHome && !mobileOpen ? (
        <div />
      ) : (
        <nav aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open={isHome}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      )}
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  ),
};

export default ResponsiveDrawer;
