/* eslint-disable */
import React, { Component, useState } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import AuthService from "../../services/auth-service";
import axios from "axios";
import SessionData from "../../services/session-data";
import logoImage from "../../images/reprolog_logo.png";
import ClearIcon from "@material-ui/icons/Clear";
const sd = new SessionData();
const API_URL = sd.getBaseURL();

//client login response type
type userLoginType = {
  user: {
    name: string;
    email: string;
    clientId: string;
  };
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
};

export default function ClientLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [clientid, setClientid] = useState("");
  const [warning, setWarning] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [login, setLogin] = useState("");

  let history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    setWarning(false);
  };
  //login the user by calling the login  method from auth-service
  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post(
        API_URL + "login",
        {
          username: username,
          password: password,
          clientid: ("DENTAL_" + clientid).toUpperCase().toString(),
        },
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
        if (data.status !== 200) throw new Error("Error loggin in");
        //storing token in local storage
        const d: userLoginType = data.data;
        localStorage.setItem("authToken", d.tokens.accessToken);
        localStorage.setItem("clientName", clientid);
        localStorage.setItem("emailId", username);
        localStorage.setItem("roleId", data.data.user.roleId);
        localStorage.setItem("rfid_enabled", data.data.user.rfidEnabled);

        if (localStorage.getItem("roleId") !== "1") {
          localStorage.setItem("branchId", data.data.user.branchId);
        }

        return history.push("/index");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setMessage(
            " Sorry, entered credentials are wrong! Please check and try again."
          );
        } else {
          setMessage(
            " Sorry, user account is not registered or account is deactivated! Please contact administrator."
          );
        }
        setWarning(true);
      });
  };

  return (
    <div className="container-fluid login-bg">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex flex-column min-vh-100  align-items-center ">
            <div className="main_logo_client">
              <img src={logoImage} />
              <div className="cloginTitle">
                <h1>Client Login Area</h1>
              </div>
            </div>
            <div className="clientLogin">
              {login ? "Login Successfull" : ""}
              <form>
                <div className="form-group">
                  <label htmlFor="inputClientId">Client Name</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setClientid(e.target.value)}
                    value={clientid}
                    id="inputClientId"
                    placeholder="client name"
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    id="exampleInputPassword1"
                    placeholder="Password"
                  ></input>
                </div>

                <button
                  type="submit"
                  onClick={(e) => handleLogin(e)}
                  className="btn btn-primary btn-lg btn-block"
                >
                  Submit
                </button>

                <p style={{ marginTop: "20px", width: "200px" }}>
                  <Link to="/pass-reset">Forgot Password?</Link>{" "}
                </p>
              </form>
              {warning ? (
                <div className="text-center" style={{ color: "red" }}>
                  <p>
                    {message}{" "}
                    <button
                      className="btn btn-sm"
                      style={{ color: "red" }}
                      onClick={(e) => handleClick(e)}
                    >
                      <ClearIcon />
                    </button>
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
