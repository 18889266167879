/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  withRouter,
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import SessionData from "../../../services/session-data";
import "../../../styles/main-style.css";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Header from "../../NavBar/header";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import addIcon from "../../../images/add-icon.svg";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Loader from "react-loader-spinner";
import suspend_av from "../../../images/suspend_autoclave.png";
import activate_av from "../../../images/activate_autoclave.png";
import sub_menu from "../../../images/sub_menu.png";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import { Breadcrumbs, Typography } from "@material-ui/core";
import ViewAutoclavesCycles from "./ViewAutoclavesCycles";
const sd = new SessionData();
const API_URL = sd.getBaseURL();

//defining type to show autoclaves
export type avResponseType = {
  name: string;
  id: number;
  autoclave_id: number;
  status: string;
  last_used_on: String;
  firstload_on: Date;
  bowie_dick: string;
  cycleNo: number;
  branch_name: string;
  autoclave_opname: string;
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ShowAutoclaves() {
  const [autoclaves, setAutoclaves] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [cycleInfo, setCycleInfo] = React.useState(false);
  const [ask, setAsk] = useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [menuStatus, setMenuStatus] = useState("");
  const [menuId, setMenuId] = useState(0);
  var [id, setId] = useState("");
  var [statusD, setStatusD] = useState("");
  const [dialogInfo, setDialogInfo] = useState({});
  let branchQuery: any = "";
  var filterList: avResponseType;
  // const openMenu = Boolean(true);
  useEffect(() => {
    handleShowAutoclaves();
    return () => {};
  }, []);

  const handleShowAutoclaves = () => {
    if (localStorage.getItem("branchId")) {
      let branchId: number = Number(localStorage.getItem("branchId"));
      branchQuery = `br.branch_id=${branchId}`;
    }
    axios
      .get(API_URL + "sterilizer/web?" + branchQuery, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("Error");
        setAutoclaves(data.data);
      })
      .catch((error) => {});
  };

  const handleAutoclaveStatus = () => {
    axios
      .post(
        API_URL + "web-register/suspend",
        {
          id: id,
          status_digit: statusD,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      )
      .then((data) => {
        if (data.status == 201) {
          setDMessage("Status Changed");
          handleShowAutoclaves();
          setLoading(false);
          setOpen(true);
        }
      })
      .catch((err) => {
        setDMessage("Error Changing Status");
        setLoading(false);
        setOpen(true);
      });
  };

  const onStatusChange = (e) => {
    setAnchorEl(null);
    autoclaves &&
      autoclaves.map((item, index) => {
        filterList = item;
        if (filterList.id == e) {
          if (filterList.status.toLowerCase() === "available") {
            setId(filterList.id.toString());
            setStatusD("0");
            setDMessage("Are you sure you want to Suspend the Autoclave?");
            setAsk(true);
          } else if (
            filterList.status.toLowerCase() === "suspend" ||
            filterList.status.toLowerCase() === "out-of-order"
          ) {
            setId(filterList.id.toString());
            setStatusD("1");
            setDMessage("Are you sure you want to Activate the Autoclave?");
            setAsk(true);
          }
        }
      });
  };

  const handleClose = () => {
    setCycleInfo(false);
    setOpen(false);
    setAsk(false);
  };

  const handleMenuClick = (event, status, id) => {
    setMenuStatus(status);
    setMenuId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  //Handle the More info Click before opening dialog
  const handleInfoDialog = (e) => {
    autoclaves &&
      autoclaves.map((item, index) => {
        filterList = item;
        if (index == e) {
          setDialogInfo(filterList);
        }
      });
    setCycleInfo(true);
  };

  //open info dialog for cycles
  const showInfoDialog = () => {
    return (
      <ViewAutoclavesCycles
        articleInfo={dialogInfo}
        cycleInfo={true}
        parentCallback={handleCallback}
      />
    );
  };
  //callback from custom info dialog
  const handleCallback = (childData) => {
    setCycleInfo(false);
  };

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Autoclave</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const questionDialog = () => {
    return (
      <Dialog
        open={ask}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "20px" }}
            id="alert-dialog-description"
          >
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ fontSize: "20px", color: "red" }}
            color="secondary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            style={{ fontSize: "20px" }}
            onClick={statusChangeCall}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const statusChangeCall = () => {
    setAsk(false);
    handleAutoclaveStatus();
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">AutoClaves</Typography>
        </Breadcrumbs>
        <Container maxWidth="lg" className="marginTop">
          <div className="row" style={{ textAlign: "center", margin: "20px" }}>
            <div className="col-11">
              {" "}
              <h2 className="jFontBold">Autoclaves List</h2>
            </div>
            <Link to="/autoclaves/register-autoclave">
              {/* <div className="col-1">
              <img className="imageLogoSmall" src={addIcon} />
            </div> */}
              {localStorage.getItem("roleId") === "1" ||
              localStorage.getItem("roleId") === "2" ? (
                <Button
                  style={{
                    borderRadius: 10,
                    backgroundColor: "#1565c0",
                    fontSize: "16px",
                    color: "#FFFFFF",
                  }}
                  variant="contained"
                  size="medium"
                >
                  Add
                </Button>
              ) : (
                ""
              )}
            </Link>
          </div>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Branch</TableCell>
                  <TableCell align="center">Cycle</TableCell>
                  {localStorage.getItem("roleId") === "1" ||
                  localStorage.getItem("roleId") === "2" ? (
                    <TableCell align="center">Action</TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {autoclaves &&
                  autoclaves.map((av, index) => {
                    const getData: avResponseType = av;
                    return (
                      <TableRow key={getData.id}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">
                          {getData.name}
                          {getData.autoclave_opname
                            ? "(" + getData.autoclave_opname + ")"
                            : " "}
                        </TableCell>
                        <TableCell align="center">
                          <span
                            className={
                              getData.status.toUpperCase() === "SUSPEND"
                                ? "colorYellow"
                                : getData.status.toUpperCase() ===
                                  "OUT-OF-ORDER"
                                ? "colorRed"
                                : ""
                            }
                          >
                            {" "}
                            {getData.status.toUpperCase() === "SUSPEND"
                              ? "NOT AVAILABLE"
                              : getData.status.toUpperCase() === "OUT-OF-ORDER"
                              ? "Out of Order".toUpperCase()
                              : getData.status.toUpperCase()}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          {getData.branch_name}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            className="cButton"
                            color="primary"
                            onClick={(e) =>
                              handleInfoDialog(e.currentTarget.value)
                            }
                            value={index}
                          >
                            view cycles
                          </Button>
                        </TableCell>
                        {localStorage.getItem("roleId") === "1" ||
                        localStorage.getItem("roleId") === "2" ? (
                          <TableCell align="center">
                            <Button
                              id="basic-button"
                              aria-controls={
                                openMenu ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openMenu ? "true" : undefined}
                              onClick={(event) =>
                                handleMenuClick(
                                  event,
                                  getData.status,
                                  getData.id
                                )
                              }
                            >
                              <img
                                src={sub_menu}
                                style={{ width: "30px", height: "30px" }}
                              />
                            </Button>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {/* <TableCell align="center"><button onClick={(e) =>onStatusChange(e.currentTarget.value)} 
                                        type="button" 
                                        value={getData.id}
                                        className={`${getData.status == "suspend" ?
                                        'btn btn-success' : 'btn btn-danger' }`}>{getData.status == "suspend" ? "Activate" : "Suspend" }
                                        </button></TableCell> */}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
        {open === true ? errorDialog() : ""}
        {cycleInfo ? showInfoDialog() : ""}
      </div>
      {ask === true ? questionDialog() : ""}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => onStatusChange(menuId)}>
          <ListItemIcon>
            {menuStatus == "suspend" || menuStatus == "out-of-order" ? (
              <img src={activate_av} width={35} />
            ) : (
              <img src={suspend_av} width={35} />
            )}
          </ListItemIcon>
          <ListItemText>
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>
              {menuStatus == "suspend" || menuStatus == "out-of-order"
                ? "Activate"
                : "Suspend"}
            </div>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
