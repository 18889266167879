/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import SessionData from "../../../services/session-data";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../../styles/main-style.css";
import "react-tagsinput/react-tagsinput.css";
import Paper from "@material-ui/core/Paper";
import SelectBranch from "../../Utils/SelectBranch";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import { Breadcrumbs, Typography } from "@material-ui/core";

export default function RegisterBILot() {
  const [biLot, setBILot] = useState<string>("");
  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = useState("");
  const [callEffect, setCallEffect] = useState<boolean>(false);
  //   let getData: BILotType;

  useEffect(() => {
    // getAllReaders();
  }, [callEffect]);

  const handleClose = () => {
    setOpen(false);
    setCallEffect(!callEffect);
  };

  //check if value is provided by user
  const formValidation = () => {
    if (biLot.length <= 0 || localStorage.getItem("branchLocation") == null) {
      setDMessage("Please enter bilot and branch");
      setOpen(true);
      return;
    }
    RegisterBILot();
  };

  const RegisterBILot = () => {
    const sd = new SessionData();
    setDialog("hidden");
    axios
      .post(
        sd.getBaseURL() + "web-standalone/register-bilot",
        {
          latest_bi_lot: biLot,
          branch_id: localStorage.getItem("branchLocation"),
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Lot Registration Failed");
          setOpen(true);
        }
        setDMessage("New Lot Registered");
        setOpen(true);
      })
      .catch(() => {
        setDMessage("Lot Registration Failed");
        setOpen(true);
      });
    //empty everything
    setBILot("");
  };

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Register BI Lot</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Register BI Lot</Typography>
        </Breadcrumbs>

        <div
          className="container dFlex dFlexCol"
          style={{ width: "80%", justifyContent: "center" }}
        >
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-sm-12 text-center">
              <h2 className="text-center jFontBold mt-3 mb-3">
                Register BI Lot
              </h2>
              <Paper className="jPadding box-shadow">
                <form>
                  <div className="form-group">
                    <label htmlFor="bilot">
                      Enter BI Lot <span className="require-field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      placeholder="Enter BI Lot"
                      onChange={(e) => setBILot(e.target.value)}
                      value={biLot}
                      id="bilot"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label htmlFor="location">
                      Branch Location <span className="require-field">*</span>
                    </label>
                    <SelectBranch />
                  </div>

                  <button
                    type="button"
                    onClick={formValidation}
                    className="btn btnColor btn-lg"
                  >
                    Register Lot
                  </button>
                </form>
              </Paper>
            </div>
          </div>
        </div>
        {open === true ? errorDialog() : ""}
      </div>
    </>
  );
}
