/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { branchLocations } from "./RegisterBranch";
import SessionData from "../../../services/session-data";

export default function DeleteBranch(props: any) {
  const [open, setOpen] = useState(false);
  const [branchName, setBranchName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  let data: branchLocations = props.data;
  let message = "Branch Deleted";

  const handleClose = () => {
    setOpen(false);
    props.parentCallback(message);
  };

  useEffect(() => {
    {
      setBranchName(data.branch_name);
      setCity(data.city);
      props.deleteOpen ? setOpen(true) : setOpen(false);
    }
  }, []);

  const DeleteBranch = async () => {
    const sd = new SessionData();
    try {
      const res = await axios.delete(
        sd.getBaseURL() + "web-register/newbranch",
        {
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },

          data: {
            branch_id: data.branch_id,
          },
        }
      );
      if (res.status === 200) {
        message = "Branch Deleted!";
      }
    } catch (err: any) {
      message = "Somethig went wrong!";
    } finally {
      setBranchName("");
      setCity("");
      handleClose();
    }
  };

  const showTheDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Delete Branch</DialogTitle>
          <DialogContent style={{ backgroundColor: "#eff9f0" }}>
            <DialogContentText>
              {/* showing articles in this load */}
              <label>
                Are you sure you want to delete this {branchName} branch?
              </label>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#eff9f0" }}>
            <Button onClick={formValidation} color="primary" autoFocus>
              Yes
            </Button>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const formValidation = () => {
    DeleteBranch();
  };

  return <div>{open ? showTheDialog() : ""}</div>;
}
