/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import SessionData from "../../../services/session-data";
import "../../../styles/main-style.css";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { FormatAlignJustify } from "@material-ui/icons";
const sd = new SessionData();
const API_URL = sd.getBaseURL();

//defining type to show articleKits
export type avResponseType = {
  ak_id: number;
  ak_name: string;
  ak_tray_name: String;
  ak_tag_name: String;
  ak_user_id: Number;
  branch_name: string;
  current_status: String;
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ShowArticleKits() {
  const [articleKits, setArticleKits] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [ask, setAsk] = useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [menuStatus, setMenuStatus] = useState("");
  const [menuId, setMenuId] = useState(0);
  var [id, setId] = useState("");
  var [statusD, setStatusD] = useState("");
  var filterList: avResponseType;
  let branchQuery: any = "";
  // const openMenu = Boolean(true);
  useEffect(() => {
    handleShowArticleKits();
    return () => {};
  }, []);

  const handleShowArticleKits = () => {
    if (localStorage.getItem("branchId")) {
      let branchId: number = Number(localStorage.getItem("branchId"));
      branchQuery = `br.branch_id=${branchId}`;
    }

    axios
      .get(API_URL + "article-kits/all?" + branchQuery, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("Error");
        setArticleKits(data.data);
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    setOpen(false);
    setAsk(false);
  };

  const handleMenuClick = (event, status, id) => {
    setMenuStatus(status);
    setMenuId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Article Set</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const questionDialog = () => {
    return (
      <Dialog
        open={ask}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "20px" }}
            id="alert-dialog-description"
          >
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ fontSize: "20px", color: "red" }}
            color="secondary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            style={{ fontSize: "20px" }}
            // onClick={}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Article Kit</Typography>
        </Breadcrumbs>
        <Container maxWidth="lg" className="marginTop">
          <div className="row" style={{ textAlign: "center", margin: "20px" }}>
            <div className="col-11">
              {" "}
              <h2 className="jFontBold">Article Kit List</h2>
            </div>
            {/* <Link to="/register-article-set/register-kit/add"> */}
            {localStorage.getItem("roleId") === "1" ? (
              <Link to="/register-article-set">
                <button className="btn btnColor btn-md">Add</button>
              </Link>
            ) : (
              ""
            )}
          </div>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="center">Article Kit Name</TableCell>
                  <TableCell align="center">Tray Name</TableCell>
                  <TableCell align="center">Articles Contain</TableCell>
                  <TableCell align="center">Branch</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {articleKits &&
                  articleKits.map((av, index) => {
                    const getData: avResponseType = av;
                    const tagArray: any = getData.ak_tag_name.split(",");
                    return (
                      <TableRow key={getData.ak_id}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">{getData.ak_name}</TableCell>
                        <TableCell align="center">
                          {getData.ak_tray_name}
                        </TableCell>
                        <TableCell>
                          {tagArray.map((value, id) => (
                            <li key={id}>{value}</li>
                          ))}
                        </TableCell>
                        <TableCell align="center">
                          {getData.branch_name}
                        </TableCell>
                        <TableCell align="center">
                          {getData.current_status}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
        {open === true ? errorDialog() : ""}
      </div>
      {ask === true ? questionDialog() : ""}
    </>
  );
}
