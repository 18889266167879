/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import SessionData from "../../../services/session-data";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../../styles/main-style.css";
import "react-tagsinput/react-tagsinput.css";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import SelectBranch from "../../Utils/SelectBranch";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import ModifyReader from "./ModifyReader";
import {
  Breadcrumbs,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import sub_menu from "../../../images/sub_menu.png";
import suspend_av from "../../../images/suspend_autoclave.png";
import activate_av from "../../../images/activate_autoclave.png";
import DeleteReader from "./DeleteReader";

//

export type readersType = {
  loc_id: number;
  reader: string;
  location: string;
  branch_name: string;
  city: string;
  flag: number;
};

export default function RegisterNewReader() {
  const [readerName, setReaderName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dMessage, setDMessage] = useState("");
  const [readers, setReaders] = useState([]);
  const [callEffect, setCallEffect] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [itemToModify, setItemToModify] = useState<any>([]);
  const [itemToDelete, setItemToDelete] = useState<any>([]);
  const [activeStatus, setActiveStatus] = useState<number>();
  var [statusD, setStatusD] = useState("");
  var [id, setId] = useState("");
  const [locId, setLocId] = useState(0);
  const [ask, setAsk] = useState(false);
  const [loading, setLoading] = useState(false);
  let getData: readersType;
  let branchQuery: any = "";
  useEffect(() => {
    getAllReaders();
  }, [callEffect]);

  const handleClose = () => {
    setOpen(false);
    setCallEffect(!callEffect);
    setAsk(false);
  };
  // Setting up the branch Id
  let branchId: any = "";
  if (localStorage.getItem("roleId") === "1") {
    branchId = localStorage.getItem("branchLocation");
  } else {
    branchId = localStorage.getItem("branchId");
  }
  //check if value is provided by user
  const formValidation = () => {
    if (
      readerName.length <= 0 ||
      location.length <= 0 ||
      localStorage.getItem("branchLocation") == null
    ) {
      setDMessage("Please provide reader, location and branch");
      setOpen(true);
      return;
    }
    RegisterLocation();
  };
  // API to get the reader
  const getAllReaders = () => {
    if (localStorage.getItem("branchId")) {
      let branchId: number = Number(localStorage.getItem("branchId"));
      branchQuery = `bl.branch_id=${branchId}`;
    }
    const sd = new SessionData();
    setDialog("hidden");
    axios
      .get(sd.getBaseURL() + "web-standalone/get-reader?" + branchQuery, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
          "staff-auth": "Bearer " + sd.getStaffToken(),
        },
      })
      .then((data) => {
        if (data.status === 200) setReaders(data.data);
      })
      .catch((err) => {});
  };
  //  To register new reader
  const sd = new SessionData();
  const RegisterLocation = () => {
    setDialog("hidden");
    axios
      .post(
        sd.getBaseURL() + "web-register/newreader",
        {
          reader: readerName,
          location: location,
          branch_id: branchId,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Not able to Add New Reader");
          setOpen(true);
        }
        setDMessage("New Reader Added");
        setOpen(true);
      })
      .catch((err) => {
        setDMessage("Not able to Add New Reader");
        setOpen(true);
      });
    //empty everything
    setReaderName("");
    setLocation("");
  };
  // Changing the status of the reader
  const handleReaderStatus = () => {
    console.log(id);
    axios
      .put(
        sd.getBaseURL() + "web-register/readerStatus",
        {
          loc_id: id,
          flag: statusD,
        },
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      )
      .then((data) => {
        if (data.status === 200) {
          setDMessage("Status Changed");
          getAllReaders();
          setLoading(false);
          setOpen(true);
        }
      })
      .catch((err) => {
        setDMessage("Error Changing Status");
        setLoading(false);
        setOpen(true);
      });
  };

  // Error Dialog box
  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Reader Information</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Question Dialouge
  const questionDialog = () => {
    return (
      <Dialog
        open={ask}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "20px" }}
            id="alert-dialog-description"
          >
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ fontSize: "20px", color: "red" }}
            color="secondary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            style={{ fontSize: "20px" }}
            onClick={statusChangeCall}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Handling the status change call
  const statusChangeCall = () => {
    setAsk(false);
    handleReaderStatus();
  };

  //  Handling the hambuger menu click
  const handleMenu = (event, getItemData: readersType) => {
    setAnchorEl(event.currentTarget);
    setItemToModify(getItemData);
    setItemToDelete(getItemData);
    setActiveStatus(getItemData.flag);
    setLocId(getItemData.loc_id);
  };

  // Handling the menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handling the Edit Click
  const handleEditClick = (e) => {
    setEditDialog(true);
    setAnchorEl(null);
  };

  // Handling the Edit Item
  const modifyItem = () => {
    return (
      <ModifyReader
        editOpen={true}
        data={itemToModify}
        parentCallback={handleModifyDialog}
      />
    );
  };

  // Handle Modify Dialog

  const handleModifyDialog = (message: any) => {
    setDMessage(message);
    setOpen(true);
    setEditDialog(false);
    setAnchorEl(null);
  };

  //To delete the use
  const handleDeleteClick = (e) => {
    setDeleteDialog(true);
    setAnchorEl(null);
  };

  // To delete the item
  const deleteItem = () => {
    return (
      <DeleteReader
        deleteOpen={true}
        data={itemToDelete}
        parentCallback={handleDeleteDialog}
      />
    );
  };

  //Delete Dialog handle
  const handleDeleteDialog = (message: any) => {
    setDMessage(message);
    setOpen(true);
    setDeleteDialog(false);
    setAnchorEl(null);
  };

  // To change the flag of the branch to activate and deactivate
  const onStatusChange = (e) => {
    setAnchorEl(null);
    readers &&
      // eslint-disable-next-line array-callback-return
      readers.map((item, index) => {
        getData = item;
        if (getData.loc_id == e) {
          if (getData.flag === 1) {
            setId(getData.loc_id.toString());
            setStatusD("0");
            setDMessage("Are you sure you want to Deactivate the Reader?");
            setAsk(true);
          } else if (getData.flag === 0) {
            setId(getData.loc_id.toString());
            setStatusD("1");
            setDMessage("Are you sure you want to Activate the Reader?");
            setAsk(true);
          }
        }
      });
  };
  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Register New Reader</Typography>
        </Breadcrumbs>

        <div
          className="container dFlex dFlexCol"
          style={{ width: "80%", justifyContent: "center" }}
        >
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-sm-12 text-center">
              <h2 className="text-center jFontBold mt-3 mb-3">
                Add New Reader
              </h2>
              <Paper className="jPadding box-shadow">
                <form>
                  <div className="form-group">
                    <label htmlFor="readerName">
                      Reader Name <span className="require-field">*</span>
                    </label>
                    <input
                      placeholder="Reader Name"
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => setReaderName(e.target.value)}
                      value={readerName}
                      id="readerName"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label htmlFor="locationName">
                      Reader Location <span className="require-field">*</span>
                    </label>
                    <input
                      placeholder="Reader Location"
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => setLocation(e.target.value)}
                      value={location}
                      id="locationName"
                    ></input>
                  </div>
                  {localStorage.getItem("roleId") === "1" ? (
                    <div className="form-group">
                      <label htmlFor="selectlevel">
                        Branch Location <span className="require-field">*</span>
                      </label>
                      <SelectBranch />
                    </div>
                  ) : (
                    ""
                  )}

                  <button
                    type="button"
                    onClick={formValidation}
                    className="btn btnColor btn-lg"
                  >
                    Add Reader
                  </button>
                </form>
              </Paper>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-sm-12 text-center">
              <h2 className="text-center jFontBold mt-3 mb-2">
                Registered Readers
              </h2>
              <List>
                <div
                  style={{
                    width: "auto",
                    margin: "0 auto",
                    textAlign: "center",
                  }}
                >
                  <Paper>
                    {readers &&
                      readers.map((item, index) => {
                        const getData: readersType = item;
                        return (
                          <ListItem key={getData.loc_id}>
                            <ListItemAvatar>
                              <Avatar>
                                <LocationCityRoundedIcon />
                              </Avatar>
                            </ListItemAvatar>
                            {getData.flag === 0 ? (
                              <ListItemText
                                primaryTypographyProps={{
                                  style: { color: "#B22222" },
                                }}
                                primary={
                                  "Reader: " +
                                  getData.reader +
                                  " - " +
                                  getData.location
                                }
                                secondary={
                                  "Branch: " +
                                  getData.branch_name +
                                  "-" +
                                  getData.city
                                }
                              />
                            ) : (
                              <ListItemText
                                primaryTypographyProps={{
                                  style: { color: "#006400" },
                                }}
                                primary={
                                  "Reader: " +
                                  getData.reader +
                                  " - " +
                                  getData.location
                                }
                                secondary={
                                  "Branch: " +
                                  getData.branch_name +
                                  "-" +
                                  getData.city
                                }
                              />
                            )}
                            <Button
                              id="basic-button"
                              aria-controls={
                                openMenu ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openMenu ? "true" : undefined}
                              onClick={(event) => handleMenu(event, getData)}
                            >
                              <img
                                src={sub_menu}
                                style={{ width: "30px", height: "30px" }}
                                alt="sub-menu"
                              />
                            </Button>
                          </ListItem>
                        );
                      })}
                  </Paper>
                </div>
              </List>
            </div>
          </div>
        </div>
        {open === true ? errorDialog() : ""}
      </div>
      <div>{editDialog === true ? modifyItem() : ""}</div>
      <div>{deleteDialog === true ? deleteItem() : ""}</div>
      <div> {ask === true ? questionDialog() : ""}</div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={(e) => handleEditClick(e)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>Edit</div>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => handleDeleteClick(e)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText defaultValue="delete">
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>Delete</div>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onStatusChange(locId)}>
          <ListItemIcon>
            {activeStatus === 0 ? (
              <img src={activate_av} width={25} alt="activate" />
            ) : (
              <img src={suspend_av} width={25} alt="suspendd" />
            )}
          </ListItemIcon>
          <ListItemText>
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>
              {activeStatus === 0 ? "Activate" : "Deactivate"}
            </div>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
