/* eslint-disable */
import React, { Component, useState } from "react";
import AuthService from "../../services/auth-service";
import axios from "axios";
import { Redirect, useHistory } from "react-router-dom";
import SessionData from "../../services/session-data";
import Header from "../NavBar/header";
import logoImage from "../../images/reprolog_logo.png";
import "../../styles/main-style.css";

const sd = new SessionData();
const API_URL = sd.getBaseURL();

export default function StaffLogin() {
  const [username, setUsername] = useState("");
  const [pin, setPin] = useState("");
  const [login, setLogin] = useState(false);
  let history = useHistory();

  const checkClientLogin = () => {
    const sd = new SessionData();
    if (!sd.getClient()) return <Redirect exact to="/main-login" />;
  };

  // //login the user by calling the login  method from auth-service
  const handleLogin = (e) => {
    e.preventDefault();
    // const userAuth = new AuthService("");
    // userAuth.staffLogin(username, pin)
    axios
      .post(
        API_URL + "staff-login",
        {
          username: username,
          pin: pin,
        },
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      )
      .then((data) => {
        if (data.status !== 200) throw new Error("Error loggin in");
        localStorage.setItem("staffAuthToken", data.data.token);

        // return <Redirect to='/autoclaves' />
        // // sd.setStaffToken(data.data.token)

        return (
          <div>
            <Header isLoggedIn="true" />
            {history.push("/index")}
            {/* {  <Redirect from="/staff-login" to="/autoclaves" />} */}
          </div>
        );
      })
      .catch((error) => {
        return false;
      });
  };

  //redirecting to client login
  const sd = new SessionData();
  if (!sd.getClient()) {
    return <Redirect exact to="/main-login" />;
  }

  return (
    <div className="container-fluid login-bg p-0 h-100 ">
      <div className="container-fluid h-100 overlay2">
        <div className="row align-items-center h-100">
          <div className="col-md-6 col-lg-8 col-sm-0 "></div>
          <div className=" col-md-6 col-lg-4 col-sm-12 overlay">
            <div>{login ? "Login Successfull" : ""}</div>
            <div className="main_logo">
              <img src={logoImage} />
              <div className="loginTitle">
                <h1>Welcome to Reprolog</h1>
              </div>
            </div>
            <div>
              <form className="loginForm">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Username</label>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder=""
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Pin</label>
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    onChange={(e) => setPin(e.target.value)}
                    value={pin}
                    id="exampleInputPassword1"
                    placeholder=""
                  ></input>
                </div>
                <div className="mt-50">
                  <button
                    type="submit"
                    onClick={(e) => handleLogin(e)}
                    className="btn btn-primary btn-lg btn-block"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
