import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SessionData from "../../../services/session-data";
import { useStyles } from "../../Styles/mui-styles";
import "../../../styles/main-style.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Container from "@material-ui/core/Container";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import { Breadcrumbs, Chip, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export type articleKitRes = {
  articleKit: string;
};

export default function RegisterAutoclave() {
  const sd = new SessionData();
  const classes = useStyles();
  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [loading, setLoading] = useState(false);
  // Article Kit Tray
  const [newTray, setNewTray] = useState("");
  const [trayValueStore, setTrayValueStore] = useState<any>([]);
  const [trayCurrentKey, setTrayCurrentKey] = useState("");
  const [articleIds, setArticleIds] = useState([]);
  const [trayAlreadyRegistered, setTrayAlreadyRegistered] = useState<any>([]);
  //   Articleset Kit
  const [articleKit, setArticleKit] = useState("");
  //   Dynamic fields for the articles/instruments
  const [inputFields, setInputFields] = useState([
    { articleName: "", articleTag: "" },
  ]);
  const handleClose = () => {
    setOpen(false);
  };
  // To check the RFID tag for the tray
  const handleNewTray = (value) => {
    setNewTray(value);

    if (trayCurrentKey === "Enter") {
      //check to see if tag is not already registered in the database
      if (articleIds.filter((tray) => tray === newTray).length <= 0) {
        //check if the scanned tray is already in the array
        if (
          trayValueStore.length <= 0 ||
          trayValueStore.filter((tray) => tray === newTray).length <= 0
        ) {
          setTrayValueStore((oldArray) => [...oldArray, newTray]);
          setNewTray(""); //empty the tray after putting in array
        }
        setNewTray("");
      } else {
        //add the tray to already-registered array
        if (
          trayAlreadyRegistered.length <= 0 ||
          trayAlreadyRegistered.filter((tray) => tray === newTray).length <= 0
        ) {
          setTrayAlreadyRegistered((oldArray) => [...oldArray, newTray]);

          setNewTray(""); //empty the tray after putting in array
        }
        setNewTray("");
      }
    }
  };

  //removing a value from array when clicking on a chip tray tag
  const handleTrayDelete = (tagToDelete: any) => () => {
    setTrayAlreadyRegistered((tag) =>
      trayAlreadyRegistered.filter((tag) => tag !== tagToDelete)
    );
  };

  //--------------------------
  const getArticleIds = () => {
    axios
      .get(sd.getBaseURL() + "web-standalone/get-article-ids", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("List empty");
        setArticleIds(data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getArticleIds();
  }, []);

  const formValidation = () => {
    if (
      articleKit.length <= 0 ||
      Object.values(inputFields[0]["articleName"]).length === 0
    ) {
      setDMessage("Please Enter Kit Name and One Article/Instrument Name!");
      setOpen(true);
      return;
    }
    registerAk();
    setLoading(true);
  };
  let articleName: string[] = [];
  let articleTag: string[] = [];
  inputFields.forEach((item, indexes) => {
    articleName.push(item["articleName"]);
    articleTag.push(item["articleTag"]);
  });
  const registerAk = () => {
    const sd = new SessionData();
    setDialog("hidden");
    axios
      .post(
        sd.getBaseURL() + "web-register/registerarticlekit",
        {
          articleKit: articleKit,
          articleName: articleName,
          articleTag: articleTag,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Article Kit Registration Failed");
          setLoading(false);
          setOpen(true);
          throw new Error("not able to register the article kit");
        }

        setDMessage("New Article Kit Registered!");
        setOpen(true);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setDMessage("Article Kit Registration Failed!");
        setOpen(true);
      });
  };

  // For the Article KIT
  // To add more article set
  const addFields = () => {
    let newField = { articleName: "", articleTag: "" };

    setInputFields([...inputFields, newField]);
  };
  //   On article name and tag, change, adding data to input fields
  const handleArticleChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };
  //   To remove the article tag/instrument
  const removeArticleData = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };
  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Attention!</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "red" }}
          >
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Register Article Kit/Set</Typography>
        </Breadcrumbs>
        <div
          className="container dFlex dFlexCol"
          style={{ width: "80%", height: "80%", justifyContent: "center" }}
        >
          <Container maxWidth="lg" className="marginTop">
            <div
              className="row"
              style={{
                textAlign: "center",
                marginRight: "50px",
                marginBottom: "10px",
              }}
            >
              <div className="col-12">
                {" "}
                <h2 className="jFontBold">Register New Article Kit/Set</h2>
              </div>
            </div>
          </Container>

          <Paper className="jPadding">
            <form>
              <div className="row mb-3">
                <div className="col-12">
                  <div>
                    <label htmlFor="additionalInfo" className="form-label">
                      Article Kit Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="articleKit"
                      value={articleKit}
                      onChange={(e) => setArticleKit(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                {/* Registering Article Set Tray */}
                <div className="col-12">
                  <div>
                    <label htmlFor="scannedTags" className="form-label">
                      Scanned Tag for Tray{" "}
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "normal",
                          color: "#FFC400",
                        }}
                      >
                        <br />
                        (Please remove tray from reader once scanned!)
                      </span>
                    </label>
                    <textarea
                      className="form-control"
                      id="scannedTags"
                      rows={1}
                      onKeyDown={(e) => setTrayCurrentKey(e.key)}
                      onChange={(e) => handleNewTray(e.target.value)}
                      value={newTray}
                    ></textarea>
                  </div>
                </div>
              </div>
              {/* Checking about the already registered article set tray */}
              <div className="row mb-3">
                <div
                  className={
                    trayAlreadyRegistered.length > 0
                      ? "col-md-4 com-sm-12 chip-list"
                      : "hide-me"
                  }
                >
                  <Chip
                    color="secondary"
                    label="Already Registered"
                    className={classes.chip}
                  />
                  {trayAlreadyRegistered.map((tray, index) => {
                    return (
                      <>
                        <li key={index}>
                          <Chip
                            color="secondary"
                            onDelete={handleTrayDelete(tray)}
                            label={tray}
                            className={classes.chip}
                          />
                        </li>
                      </>
                    );
                  })}
                </div>
                <div
                  className={
                    trayAlreadyRegistered.length > 0
                      ? "col-md-8 col-sm-12"
                      : "col-md-12"
                  }
                >
                  <div className="chip-list">
                    {trayValueStore.map((tray, index) => {
                      return (
                        <>
                          <li key={index}>
                            <Chip
                              color="primary"
                              label={tray}
                              className={classes.chip}
                            />
                          </li>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <label htmlFor="additionalInfo" className="form-label">
                Please Enter the Article Name and Scanned Tag for Article
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#FFC400",
                  }}
                >
                  <br />
                  (Please remove article(s) from reader once scanned!)
                </span>
              </label>
              <div className="mb-3">
                {inputFields.map((input, index) => {
                  return (
                    <div key={index}>
                      <div className="row mb-3">
                        <div className="col-5">
                          <input
                            className="form-control"
                            name="articleName"
                            placeholder="Article Name"
                            value={input.articleName}
                            onChange={(e) => handleArticleChange(index, e)}
                          />
                        </div>
                        <div className="col-5 mb-3">
                          <input
                            className="form-control"
                            name="articleTag"
                            placeholder="Article RFID Tag"
                            value={input.articleTag}
                            onChange={(e) => handleArticleChange(index, e)}
                          />
                        </div>
                        <div className="col-2 mb-3">
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => removeArticleData(index)}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-success btn-md"
                    onClick={addFields}
                  >
                    Add More Instrument
                  </button>
                </div>
              </div>

              <button
                type="button"
                onClick={formValidation}
                className="btn btnColor btn-md"
              >
                Register Article Kit
              </button>
            </form>
            <div className="row"></div>
          </Paper>
          {loading ? (
            <div className="jCentered">
              <Loader
                type="Oval"
                color="#3f51b5"
                height={70}
                width={70}
                timeout={10000} //10 secs
              />{" "}
            </div>
          ) : (
            ""
          )}
        </div>
        {open === true ? errorDialog() : ""}
      </div>
    </>
  );
}
