import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Dashboard from "./dashboard";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
// Components

export default function App() {
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <div style={{ marginLeft: "260px" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to="/index">
                Home
              </Link>
              <Typography color="textPrimary">Dashboard</Typography>
            </Breadcrumbs>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
              <Route>
                <div className="">
                  <Dashboard />
                </div>
              </Route>
            </Switch>
          </div>
        </Router>
      </MuiPickersUtilsProvider>
    </>
  );
}
