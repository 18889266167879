/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import SessionData from "../../../services/session-data";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../Styles/mui-styles";
import "../../../styles/main-style.css";
import "react-tagsinput/react-tagsinput.css";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
export default function RegisterServiceReasons() {
  const [serviceReasons, setServiceReasons] = useState<string>("");
  const [valueStore, setValueStore] = useState<any>([]);
  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [ooserviceReasonsList, setOoserviceReasonsList] = useState<any[]>([]);
  const finalObj: any = {
    oosReasons: [],
  };

  useEffect(() => {
    getOoServiceReasons();
  }, []);

  const classes = useStyles();

  //removing a value from array when clicking on a chip tag
  const handleDelete = (tagToDelete: any) => () => {
    setValueStore((tag) => valueStore.filter((tag) => tag !== tagToDelete));
  };

  const handleClose = () => {
    setOpen(false);
  };

  //check if value is provided by user
  const formValidation = () => {
    if (valueStore.length <= 0 && serviceReasons.length <= 0) {
      setDMessage("Please add atleast one reason");
      setOpen(true);
      return;
    }
    //insert values in final array
    finalObj.oosReasons.push(...valueStore);

    if (serviceReasons.length > 0) finalObj.oosReasons.push(serviceReasons);
    RegisterServiceReasons();
  };
  //Fetch All Service Reasons from API
  const getOoServiceReasons = () => {
    const sd = new SessionData();
    axios
      .get(sd.getBaseURL() + "web-register/getOosReason", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("List empty");
        setOoserviceReasonsList(data.data);
      })
      .catch((err) => {});
  };
  const RegisterServiceReasons = () => {
    const sd = new SessionData();
    setDialog("hidden");
    // finalObj.oosReasons.push(serviceReasons)

    axios
      .post(
        sd.getBaseURL() + "web-register/addoosreason",
        {
          finalObj,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201)
          throw new Error("Error, Not able to insert data");
        setDMessage("New Service Reasons Added");
        setOpen(true);
      })
      .catch((err) => {});
    //empty everything
    setServiceReasons("");
    setValueStore([]);
  };

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Service Reasons</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //insert values in array
  const handleNewTag = (value) => {
    //if the last character is a comma,  then empty the service reasons
    if (value.charAt(value.length - 1) === ",") {
      const tagToAdd = value.substring(0, value.length - 1);
      //check if tag already in array
      if (valueStore.filter((tag) => tag === tagToAdd).length <= 0) {
        setValueStore((oldArray) => [...oldArray, tagToAdd]); //get the value from serviceReasons and add in to array
        setServiceReasons(""); //empty setServiceReasons
      } else {
        setServiceReasons("");
        setDMessage("Value Already exists in the list");
        setOpen(true);
      }
    } else {
      setServiceReasons(value);
    }
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Register Service Reasons</Typography>
        </Breadcrumbs>

        <div
          className="container dFlex dFlexCol"
          style={{ width: "80%", height: "80%", justifyContent: "center" }}
        >
          <h2 className="text-center jFontBold">Add Service Reasons</h2>
          <Paper className="jPadding box-shadow">
            <form>
              <div className="form-group">
                <label htmlFor="autoclaveName">Service Reason(s)</label>
                <Stack spacing={2}>
                  <Autocomplete
                    id="autoclaveName"
                    freeSolo
                    options={ooserviceReasonsList.map(
                      (option) => option.outof_service_reasons
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => handleNewTag(e.target.value)}
                        placeholder="separate multiple items with comma(,)"
                        label="Add Service Reasons"
                      />
                    )}
                    value={serviceReasons}
                    onChange={(event, newValue) => handleNewTag(newValue)}
                  />
                </Stack>
              </div>
              <div className={classes.displayFlex}>
                {valueStore.map((tag, index) => {
                  return (
                    <>
                      <li key={index}>
                        <Chip
                          color="primary"
                          label={tag}
                          onDelete={handleDelete(tag)}
                          className={classes.chip}
                        />
                      </li>
                    </>
                  );
                })}
              </div>
              <button
                type="button"
                onClick={formValidation}
                className="btn btnColor btn-lg"
              >
                Submit
              </button>
            </form>
            <div className="row">
              {/* <div className="col" >
                    <div style={myStyles} >Please fill autoclave name, cycle no, and bowie dick to register</div>
                </div> */}
            </div>
          </Paper>
        </div>
        {open === true ? errorDialog() : ""}
      </div>
    </>
  );
}
