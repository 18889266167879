import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../../../styles/main-style.css";
import { useStyles } from "../../../Styles/mui-styles";
import Button from "@material-ui/core/Button";
import { usedArticleType } from "./get-used-article-details";
import Paper from "@material-ui/core/Paper";

export default function UsedArticleInfoDialog(props: any) {
  /*React Dialog*/
  const [open, setOpen] = React.useState(false);
  const [pouchData, setPouchData] = React.useState<any>([]);
  var propsData: usedArticleType = props.articleInfo;
  const classes = useStyles();

  useEffect(() => {
    propsData = props.articleInfo;
    setPouchData(propsData.pouch_articles.split(",").slice(0, -1));
    {
      props.open ? setOpen(true) : setOpen(false);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    props.parentCallback(false);
  };

  const showTheDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {propsData.type}
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#eff9f0" }}>
            <DialogContentText>
              <div className="container">
                <h5 className="py-2 text-center">Article Details</h5>
                <div className="row justify-content-md-center">
                  <div className="col-md-3 col-sm-6">
                    <Paper
                      className={classes.paperback}
                      style={{ textAlign: "center" }}
                    >
                      <p className="fw-500">Control No</p>
                      <p>{propsData.control_no}</p>
                    </Paper>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <Paper
                      className={classes.paperback}
                      style={{ textAlign: "center" }}
                    >
                      <p className="fw-500">Total Cycles</p>
                      <p>{propsData.cycles}</p>
                    </Paper>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <Paper
                      className={classes.paperback}
                      style={{ textAlign: "center" }}
                    >
                      <p className="fw-500">Used By</p>
                      <p>{propsData.used_by}</p>
                    </Paper>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <Paper
                      className={classes.paperback}
                      style={{ textAlign: "center" }}
                    >
                      <p className="fw-500">Used Date</p>
                      <p>{propsData.date_used.split("T")[0]}</p>
                    </Paper>
                  </div>
                </div>
                <h5 className="py-2 my-2 text-center">Autoclave Details</h5>
                <div className="row justify-content-md-center">
                  <div className="col-md-3 col-sm-6">
                    <Paper
                      className={classes.paperback}
                      style={{ textAlign: "center" }}
                    >
                      <p className="fw-500">Autoclave</p>
                      <p>{propsData.autoclave_name}</p>
                    </Paper>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <Paper
                      className={classes.paperback}
                      style={{ textAlign: "center" }}
                    >
                      <p className="fw-500">Cycle No</p>
                      <p>{propsData.cycle_no}</p>
                    </Paper>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <Paper
                      className={classes.paperback}
                      style={{ textAlign: "center" }}
                    >
                      <p className="fw-500">Load Date</p>
                      <p>{propsData.load_finished_at.split("T")[0]}</p>
                    </Paper>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <Paper
                      className={classes.paperback}
                      style={{ textAlign: "center" }}
                    >
                      <p className="fw-500">Load By</p>
                      <p>{propsData.load_finished_by}</p>
                    </Paper>
                  </div>
                </div>
                {pouchData?.length > 1 ? (
                  <>
                    <h5 className="py-2 my-2 text-center">Pouch Articles</h5>
                    <div className="row justify-content-md-center">
                      <div className="col-md-6 col-sm-6">
                        <Paper
                          className={classes.paperback}
                          style={{ textAlign: "left" }}
                        >
                          <p></p>
                          <ul>
                            {pouchData.map((item, index) => (
                              <li key={index}>
                                {item}
                              </li>
                            ))}
                          </ul>
                        </Paper>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#eff9f0" }}>
            <Button onClick={handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return <div>{open ? showTheDialog() : ""}</div>;
}
