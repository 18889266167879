import React, { useState } from "react";
import axios from "axios";
import SessionData from "../../services/session-data";
import { Breadcrumbs, Paper, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "react-loader-spinner";
import Button from "@material-ui/core/Button";
import AppBarAndDrawer from "../Dashboard/AppBarAndDrawer";
import { Link } from "react-router-dom";
import SelectBranch from "../Utils/SelectBranch";

export default function CheckTagStatus() {
  const rfidEnabled = localStorage.getItem("rfid_enabled");
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [loading, setLoading] = useState(false);
  // enterId  = tagId
  const [enterId, setEnterId] = useState("");
  const [discardId, setDiscardId] = useState("");
  const [removalReason, setRemovalReason] = useState<string>("");
  const [errorColor, setErrorColor] = useState(true);
  const [itemName, setItemName] = useState<string>("");
  const [totalCycles, setTotalCycles] = useState<string>("");
  const [processedDate, setProcessedDate] = useState<any>("");
  const [sterilizer, setSterilizer] = useState<string>("");
  const [certifiedBy, setCertifiedBy] = useState<string>("");
  const [cycleNo, setCycleNo] = useState<number | null>(0);
  const [status, setStatus] = useState<string>("");
  const [expiry, setExpiry] = useState<any>("");
  const [controlNo, setControlNo] = useState<null | number>(0);
  const [ask, setAsk] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [getInfo, setGetInfo] = useState<boolean>(false);
  // For discarding item
  const [articleId, setArticleId] = useState<number>(0);
  const [loadedId, setLoadedId] = useState<number>(0);
  const [articleStatus, setArticleStatus] = useState<string>("");
  const [activityId, setActivityId] = useState<number>(0);
  const [getArticleInfoEndPoint, setGetArticleInfoEndPoint] =
    useState<string>("");
  const [checkStatusEndPoint, setCheckStatusEndPoint] = useState<string>("");
  const sd = new SessionData();
  let branch_id: any = "";
  if (localStorage.getItem("roleId") !== "1") {
    branch_id = localStorage.getItem("branchId");
  } else {
    branch_id = localStorage.getItem("branchLocation");
  }

  const handleClose = () => {
    setOpen(false);
    setAsk(false);
  };

  const formValidation = () => {
    if (enterId == null) {
      setDMessage("Please Scan the required Item(s)");
      setOpen(true);
      return;
    } else if (enterId.length <= 0) {
      setDMessage("Please Scan the required Item(s)");
      setOpen(true);
      return;
    }
    getArticleInfo();
    setLoading(true);
  };

  // First to get the article information
  const getArticleInfo = async () => {
    // To check the state of RFID to set the endpoint
    if (rfidEnabled === "0") {
      setGetArticleInfoEndPoint("get-article-info-using-controlno");
      setCheckStatusEndPoint("check-status-using-control-no");
    } else {
      setGetArticleInfoEndPoint("get-article-info");
      setCheckStatusEndPoint("check-status");
    }
    await axios
      .get(
        sd.getBaseURL() +
          `web-standalone/${getArticleInfoEndPoint}/${enterId}/${branch_id}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + localStorage.getItem("staffAuthToken"),
          },
        }
      )
      .then(async (result) => {
        if (result.data.status === 205) {
          setLoading(false);
          setGetInfo(false);
          setErrorColor(true);
          setDMessage(
            "Sorry, Scan item was not found, Please Check Tag Id and Branch Location!"
          );
          setOpen(true);
          setEnterId("");
        } else {
          if (
            result.data.latest_activity_id !== null &&
            result.data.latest_activity_id === 20761
          ) {
            if (
              result.data.article_type === "biological-indicator" ||
              result.data.article_type === "class-2" ||
              result.data.article_type === "class-5"
            ) {
              setLoading(false);
              setGetInfo(false);
              setErrorColor(true);
              setDMessage(
                "B1 and C5 can't be used as instrunments on patients!"
              );
              setOpen(true);
            } else {
              await axios
                .get(
                  sd.getBaseURL() +
                    `web-standalone/${checkStatusEndPoint}/${enterId}`,
                  {
                    method: "GET",
                    headers: {
                      Authorization: "Bearer " + sd.getClientToken(),
                      "staff-auth":
                        "Bearer " + localStorage.getItem("staffAuthToken"),
                    },
                  }
                )
                .then(async (res) => {
                  // console.log(res.data.);
                  let expiryDays = 30 - (res.data.load_finished_at % 365);
                  if (res.data.status === 205) {
                    setGetInfo(false);
                    setErrorColor(true);
                    setDMessage("This item is held for the B1 test result!");
                    setLoading(false);
                    setOpen(true);
                  } else {
                    setArticleStatus("ready_to_process");
                    setActivityId(19407);
                    setArticleId(res.data.id);
                    setLoadedId(res.data.loaded_id);
                    if (expiryDays < 1) {
                      callDiscardArticle();
                    } else {
                      let processDt = new Date(
                        res.data.processed_date
                      ).toLocaleDateString("en-CA", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      });
                      if (res.data.load_finished_at > 30) {
                        setEnterId("");
                        setErrorColor(true);
                        setDMessage(
                          "Found the required Information, but the item is sterillized for more than 30 days, please reprocess before using it again!"
                        );
                        setStatus("Expired!");
                        setOpen(true);
                      } else {
                        setErrorColor(false);
                        setDMessage("Found the required Information!");
                        setStatus("Available");
                      }
                      setDiscardId(enterId);
                      setEnterId("");
                      setLoading(false);
                      setGetInfo(true);
                      setExpiry(expiryDays);
                      setItemName(result.data.type);
                      setSterilizer(res.data.autoclave_name);
                      setCertifiedBy(res.data.certified_by);
                      setTotalCycles(res.data.total_cycles);
                      setControlNo(res.data.control_no);
                      setCycleNo(res.data.cycle_no);
                      setProcessedDate(processDt);
                    }
                  }
                })
                .catch((err) => {
                  setEnterId("");
                  setGetInfo(false);
                  setLoading(false);
                  setErrorColor(true);
                  setDMessage("Scan Item(s) not Found");
                  setOpen(true);
                });
            }
          } else if (result.data.latest_activity_id === 19358) {
            setEnterId("");
            setLoading(false);
            setGetInfo(false);
            setErrorColor(true);
            setDMessage("Heads Up!!, this item supposed to be in autoclave");
            setOpen(true);
          } else {
            setEnterId("");
            setLoading(false);
            setGetInfo(false);
            setErrorColor(true);
            setDMessage("Item is not sterillized, reprocess before use!");
            setOpen(true);
          }
        }
      })
      .catch((err) => {
        setEnterId("");
        setGetInfo(false);
        setLoading(false);
        setErrorColor(true);
        setDMessage("Scan Item(s) not Found");
        setOpen(true);
      });
  };

  // To discard the Article

  const discardArticle = () => {
    setDMessage("Are you sure you want to discard this activity?");
    setAsk(true);
  };

  const callDiscardArticle = async () => {
    await axios
      .post(
        sd.getBaseURL() + `web-standalone/discard-item/${discardId}`,
        {
          removal_reason: removalReason,
          loaded_id: loadedId,
          article_status: articleStatus,
          latest_activity: activityId,
          article_id: articleId,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + localStorage.getItem("staffAuthToken"),
          },
        }
      )
      .then((res) => {
        setDiscardId("");
        setAsk(false);
        setGetInfo(false);
        setErrorColor(true);
        setDMessage("This item has been discarded!");
        setLoading(false);
        setOpen(true);
      })
      .catch((err) => {
        setDiscardId("");
        setAsk(false);
        setGetInfo(false);
        setErrorColor(true);
        setDMessage("Something went wrong, while discarding the item!");
        setLoading(false);
        setOpen(true);
      });
  };

  //-------------------------------
  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Information</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            color={errorColor ? "error" : "primary"}
            style={{ fontWeight: "bold" }}
          >
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  //-------------------------------
  // Question Dialouge
  const questionDialog = () => {
    return (
      <Dialog
        open={ask}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
        <div className="container">
          <label htmlFor="removal_reason" className="jFontBold">
            Removal Reason <span style={{ color: "red" }}>*</span>
          </label>
          <input
            placeholder="Removal Reason"
            type="text"
            className="form-control"
            required
            id="removal_reason"
            onChange={(e) => setRemovalReason(e.target.value)}
            value={removalReason}
            autoComplete="false"
          ></input>
        </div>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "20px" }}
            id="alert-dialog-description"
          >
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ fontSize: "20px", color: "red" }}
            color="secondary"
            autoFocus
          >
            Cancel
          </Button>
          {removalReason ? (
            <Button style={{ fontSize: "20px" }} onClick={callDiscardArticle}>
              Yes
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Check Status</Typography>
        </Breadcrumbs>
        <div
          className="container dFlex dFlexCol"
          style={{ width: "100%", height: "80%", justifyContent: "center" }}
        >
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-sm-12 text-center">
              <h2 className="text-center jFontBold mt-3 mb-3">Check Status</h2>
              <Paper className="jPadding box-shadow">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group">
                    <label htmlFor="readerName">
                      {rfidEnabled === "0"
                        ? "Enter Control No."
                        : "Scan Item/ Instrument/ Article"}
                    </label>
                    <input
                      autoFocus
                      placeholder={
                        rfidEnabled === "0"
                          ? "Enter Control No."
                          : "Scan Item/ Instrument/ Article"
                      }
                      type="text"
                      className="form-control"
                      required
                      id="scanItem"
                      onChange={(e) => setEnterId(e.target.value)}
                      value={enterId}
                      autoComplete="false"
                    ></input>
                  </div>
                  {localStorage.getItem("roleId") === "1" ? (
                    <div className="form-group">
                      <label htmlFor="location">Select Branch Name</label>
                      <SelectBranch />
                    </div>
                  ) : (
                    ""
                  )}

                  <button
                    type="button"
                    onClick={formValidation}
                    className="btn btnColor btn-lg"
                  >
                    Check Status
                  </button>
                </form>
              </Paper>
            </div>
          </div>
          {loading ? (
            <div className="jCentered">
              <Loader
                type="Oval"
                color="#3f51b5"
                height={70}
                width={70}
                timeout={10000} //10 secs
              />{" "}
            </div>
          ) : (
            ""
          )}
          {getInfo ? (
            <div className="row justify-content-md-center">
              <div className="col-lg-8 col-sm-12 text-center">
                <h2 className="text-center jFontBold mt-3 mb-1">Status</h2>
                <span className="jFontBold mb-2">
                  (Please scan one item at a time)
                </span>
                <Paper className="jPadding box-shadow">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <h5 style={{ fontWeight: "bold" }}>Item Name</h5>
                      <span
                        className="form-control"
                        style={{ fontWeight: "bold", color: "#052CA3" }}
                      >
                        {itemName}
                      </span>
                    </div>
                    <div className="form-group col-md-6">
                      <h5 style={{ fontWeight: "bold" }}>Total Cycles</h5>
                      <span
                        className="form-control"
                        style={{ fontWeight: "bold", color: "#052CA3" }}
                      >
                        {totalCycles}
                      </span>
                    </div>
                    <div className="form-group col-md-6">
                      <h5 style={{ fontWeight: "bold" }}>Processed</h5>
                      <span
                        className="form-control"
                        style={{ fontWeight: "bold", color: "#052CA3" }}
                      >
                        {processedDate}
                      </span>
                    </div>
                    <div className="form-group col-md-6">
                      <h5 style={{ fontWeight: "bold" }}>Sterilizer</h5>
                      <span
                        className="form-control"
                        style={{ fontWeight: "bold", color: "#052CA3" }}
                      >
                        {sterilizer}
                      </span>
                    </div>
                    <div className="form-group col-md-6">
                      <h5 style={{ fontWeight: "bold" }}>Certified By</h5>
                      <span
                        className="form-control"
                        style={{ fontWeight: "bold", color: "#052CA3" }}
                      >
                        {certifiedBy}
                      </span>
                    </div>
                    <div className="form-group col-md-6">
                      <h5 style={{ fontWeight: "bold" }}>Cycle #</h5>
                      <span
                        className="form-control"
                        style={{ fontWeight: "bold", color: "#052CA3" }}
                      >
                        {cycleNo}
                      </span>
                    </div>
                    <div className="form-group col-md-4">
                      <h5 style={{ fontWeight: "bold" }}>Status</h5>
                      <span
                        className="form-control"
                        style={{
                          fontWeight: "bold",
                          color: errorColor ? "red" : "green",
                        }}
                      >
                        {status}
                      </span>
                    </div>
                    <div className="form-group col-md-4">
                      <h5 style={{ fontWeight: "bold" }}>Expiry Days</h5>
                      <span
                        className="form-control"
                        style={{ fontWeight: "bold", color: "#052CA3" }}
                      >
                        {expiry}
                      </span>
                    </div>
                    <div className="form-group col-md-4">
                      <h5 style={{ fontWeight: "bold" }}>Control No.</h5>
                      <span
                        className="form-control"
                        style={{ fontWeight: "bold", color: "#052CA3" }}
                      >
                        {controlNo}
                      </span>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={discardArticle}
                    className="btn btn-danger btn-lg m-1"
                  >
                    Discard Item
                  </button>
                  <button
                    onClick={() => setGetInfo(false)}
                    type="button"
                    className="btn btn-success btn-lg m-1"
                  >
                    Done
                  </button>
                </Paper>
                {loading ? (
                  <div className="jCentered">
                    <Loader
                      type="Oval"
                      color="#3f51b5"
                      height={70}
                      width={70}
                      timeout={10000} //10 secs
                    />{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div>{open === true ? errorDialog() : ""}</div>
        <div> {ask === true ? questionDialog() : ""}</div>
      </div>
    </>
  );
}
