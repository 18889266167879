import React, { useState, useEffect } from "react";
import axios from "axios";
import SessionData from "../../../services/session-data";
import Stack from "@mui/material/Stack";
import { Breadcrumbs, Paper, Typography } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "react-loader-spinner";
import Button from "@material-ui/core/Button";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import { Link } from "react-router-dom";
export default function RegisterArticleType() {
  const [articleTypesList, setArticleTypesList] = useState<any[]>([]);
  const [code, setCode] = useState("");
  const [type, setType] = useState("");
  const [manuf, setManuf] = useState("");
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [loading, setLoading] = useState(false);
  const sd = new SessionData();
  const handleClose = () => {
    setOpen(false);
  };

  //Fetch All Article Types from API
  const getArticleTypes = () => {
    axios
      .get(sd.getBaseURL() + "web-standalone/get-article-types", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("List empty");
        setArticleTypesList(data.data);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  useEffect(() => {
    getArticleTypes();
  }, []);

  //Register Article Type Request
  const registerArticleType = () => {
    axios
      .post(
        sd.getBaseURL() + "web-standalone/register-type",
        {
          type: type,
          code: code,
          manufacturer: manuf,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Type Registration Failed");
          setLoading(false);
          setOpen(true);
          throw new Error("not able to register");
        }
        setDMessage("New Article Type Registered!");
        setOpen(true);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setDMessage("Type Registration Failed");
        setOpen(true);
      });
  };
  //----------------------------
  const formValidation = () => {
    if (code == null || type == null || manuf == null) {
      setDMessage("Please fill all the required fields");
      setOpen(true);
      return;
    } else if (code.length <= 0 || type.length <= 0 || manuf.length <= 0) {
      setDMessage("Please fill all the required fields");
      setOpen(true);
      return;
    }
    registerArticleType();
    setLoading(true);
  };
  //-------------------------------
  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Autoclave</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  //-------------------------------

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Register Article Type</Typography>
        </Breadcrumbs>
        <div
          className="container dFlex dFlexCol"
          style={{ width: "100%", height: "80%", justifyContent: "center" }}
        >
          <h2 className="text-center jFontBold color-blue mt-4 mb-4">
            Register Article Type
          </h2>
          <Paper
            className="jPadding dFlex box-shadow"
            style={{ justifyContent: "center", width: "auto", margin: "0 0" }}
          >
            <form>
              <div>
                <div className="row mb-3">
                  <div className="col-12 col-sm-12" style={{ display: "flex" }}>
                    <Stack spacing={2} sx={{ width: 300 }}>
                      <Autocomplete
                        id="Code"
                        freeSolo
                        options={articleTypesList.map((option) => option.code)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => setCode(e.target.value)}
                            label="Code"
                          />
                        )}
                        value={code}
                        onChange={(event, newValue) => setCode(newValue)}
                      />
                      <Autocomplete
                        id="Type"
                        freeSolo
                        options={articleTypesList.map((option) => option.type)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => setType(e.target.value)}
                            label="Type"
                          />
                        )}
                        value={type}
                        onChange={(event, newValue) => setType(newValue)}
                      />
                      <Autocomplete
                        id="Manufacturer"
                        freeSolo
                        options={articleTypesList.map(
                          (option) => option.manufacturer
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(e) => setManuf(e.target.value)}
                            label="Manufacturer"
                          />
                        )}
                        value={manuf}
                        onChange={(event, newValue) => setManuf(newValue)}
                      />
                    </Stack>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    onClick={formValidation}
                    className="btn btnColor btn-lg text-center"
                  >
                    Register Type
                  </button>
                </div>
              </div>
            </form>
            <div className="row"></div>
          </Paper>
          {loading ? (
            <div className="jCentered">
              <Loader
                type="Oval"
                color="#3f51b5"
                height={70}
                width={70}
                timeout={10000} //10 secs
              />{" "}
            </div>
          ) : (
            ""
          )}
        </div>
        {open === true ? errorDialog() : ""}
      </div>
    </>
  );
}
