/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import SessionData from "../../../services/session-data";
import {
  Breadcrumbs,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useStyles } from "../../Styles/mui-styles";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "react-loader-spinner";
import Button from "@material-ui/core/Button";
import SelectBranch from "../../Utils/SelectBranch";
import getStaffList from "../../Utils/FetchStaffList";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import PersonSharpIcon from "@material-ui/icons/PersonSharp";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import sub_menu from "../../../images/sub_menu.png";
import suspend_av from "../../../images/suspend_autoclave.png";
import activate_av from "../../../images/activate_autoclave.png";
import ModifyStaff from "./ModifyStaff";
import DeleteStaff from "./DeleteStaff";
export type locData = {
  id: number;
  name: string;
  username: string;
  level: string;
  branch_name: string;
  flag: number;
  pin: string;
};
const sd = new SessionData();

export default function RegisterStaff() {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [pin, setPin] = useState<string>("");
  const [wristband, setWristband] = useState("");
  const [level, setLevel] = useState("");
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [loading, setLoading] = useState(false);

  const [staffData, setStaffData] = useState<any>([]);
  const [branchData, setBranchData] = useState<any>([]);
  const [usernameError, setUserNameError] = useState("");
  const [pinError, setPinError] = useState("");
  const [callEffect, setCallEffect] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [itemToModify, setItemToModify] = useState<any>([]);
  const [itemToDelete, setItemToDelete] = useState<any>([]);
  const [activeStatus, setActiveStatus] = useState<number>();
  var [statusD, setStatusD] = useState("");
  var [id, setId] = useState("");
  const [staffId, setStaffId] = useState(0);
  const [ask, setAsk] = useState(false);
  const getStaffDetails = getStaffList();
  let getData: locData;
  const handleClose = () => {
    setOpen(false);
    setCallEffect(!callEffect);
  };

  const registerNewStaff = () => {
    axios
      .post(
        sd.getBaseURL() + "staff",
        {
          username: username,
          name: firstName.concat(" ", lastName),
          pin: pin,
          level: level,
          branch_id: localStorage.getItem("branchLocation"),
          wristband: wristband.length <= 0 ? "" : wristband,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Staff Registration Failed");
          setLoading(false);
          setOpen(true);
          throw new Error("Cant register Srtaff");
        }
        setUsername("");
        setFirstName("");
        setLastName("");
        setPin("");
        setLevel("");
        setWristband("");
        setDMessage("New Staff Registered!");
        setOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        setDMessage("Staff Registration Failed");
        setLoading(false);
        setOpen(true);
        throw new Error("not able to register the staff");
      });
  };

  //-------------------------
  const getStaff = () => {
    axios
      .get(sd.getBaseURL() + "staff", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("List empty");
        setStaffData(data.data.data);
      })
      .catch((err) => {});
  };
  //-------------------------
  useEffect(() => {
    getStaffDetails.request();
    getStaff();
    getBranchLocations();
  }, [callEffect]);
  //-------------------------
  const getBranchLocations = () => {
    axios
      .get(sd.getBaseURL() + "web-standalone/get-branch", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("List empty");
        setBranchData(data.data);
      })
      .catch((err) => {});
  };
  //-------------------------
  //check if username is already registered
  const handleUserName = (userName) => {
    setUsername(userName.toString().trim());
    if (
      staffData.filter(
        (item) => item.username.toLowerCase() === userName.toLowerCase()
      ).length > 0
    ) {
      setUserNameError("username already in use");
    } else {
      setUserNameError("");
    }
  };
  //------------------------
  //check if the length of pin
  const handlePin = (epin) => {
    setPin(epin);
    if (epin.length < 4) setPinError("Pin must be 4 to 6 charachets long");
    else setPinError("");
  };
  //------------------------

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Staff Information</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //check if value is provided by user
  const formValidation = () => {
    //check if field values are valid
    if (usernameError.length <= 0 && pinError.length <= 0 && level) {
      //check other fields
      if (
        username.length <= 0 ||
        firstName.length <= 0 ||
        lastName.length <= 0 ||
        pin!.toString().length < 4 ||
        level.length <= 0 ||
        localStorage.getItem("branchLocation") == null
      ) {
        setDMessage("Please fill all the fields");
        setOpen(true);
        return;
      }
    } else {
      setDMessage("Please fill all the required fields");
      setOpen(true);
      return;
    }

    registerNewStaff();
    setLoading(true);
  };

  // Handling the menu call
  const handleMenu = (event, getItemData: locData) => {
    setAnchorEl(event.currentTarget);
    setItemToModify(getItemData);
    setItemToDelete(getItemData);
    // setActiveStatus(getItemData.flag);
    // setBranchId(getItemData.branch_id);
  };

  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // handle Edit Click

  const handleEditClick = (e) => {
    setEditDialog(true);
    setAnchorEl(null);
  };

  //TO Handle Delete Click
  const handleDeleteClick = (e) => {
    setDeleteDialog(true);
    setAnchorEl(null);
  };

  // Handle Modify Item
  const modifyItem = () => {
    return (
      <ModifyStaff
        editOpen={true}
        data={itemToModify}
        parentCallback={handleModifyDialog}
      />
    );
  };

  // Modify Dialog handle
  const handleModifyDialog = (message: any) => {
    setDMessage(message);
    setOpen(true);
    setEditDialog(false);
    setAnchorEl(null);
  };

  //to delete the item
  const deleteItem = () => {
    return (
      <DeleteStaff
        deleteOpen={true}
        data={itemToDelete}
        parentCallback={handleDeleteDialog}
      />
    );
  };

  //Delete Dialog handle
  const handleDeleteDialog = (message: any) => {
    setDMessage(message);
    setOpen(true);
    setDeleteDialog(false);
    setAnchorEl(null);
  };

  // To change the flag of the branch to activate and deactivate
  const onStatusChange = (e) => {
    setAnchorEl(null);
    getStaffDetails.data &&
      getStaffDetails.data.map((item, index) => {
        getData = item;
        if (getData.id == e) {
          if (getData.flag === 1) {
            setId(getData.id.toString());
            setStatusD("0");
            setDMessage("Are you sure you want to Deactivate the User?");
            setAsk(true);
          } else if (getData.flag === 0) {
            setId(getData.id.toString());
            setStatusD("1");
            setDMessage("Are you sure you want to Activate the User?");
            setAsk(true);
          }
        }
      });
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Register Staff</Typography>
        </Breadcrumbs>
        <div
          className="container dFlex dFlexCol text-center"
          style={{
            width: "80%",
            justifyContent: "center",
            margin: "auto",
          }}
        >
          <div className="row justify-content-md-center">
            <div className="col-lg-8 col-sm-12 text-center">
              <h2 className="text-center jFontBold mt-4 mb-4">
                Register Staff
              </h2>
              <Paper className="jPadding box-shadow">
                <form>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label htmlFor="username" className="form-label">
                          Username <span className="require-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="username"
                          onChange={(e) => handleUserName(e.target.value)}
                          value={username}
                        />
                        <div
                          className={
                            usernameError.length > 0
                              ? "chip-list"
                              : "chip-list hide-me"
                          }
                        >
                          <Chip
                            color="secondary"
                            label={usernameError}
                            className={classes.chip}
                          />
                        </div>
                        {/* {usernameError} */}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <div>
                          <label htmlFor="firstName" className="form-label">
                            First Name <span className="require-field">*</span>
                          </label>
                          <input
                            className="form-control"
                            id="firstName"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div>
                          <label htmlFor="lastName" className="form-label">
                            Last Name <span className="require-field">*</span>
                          </label>
                          <input
                            className="form-control"
                            id="lastName"
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <label htmlFor="pin" className="form-label">
                          Pin <span className="require-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="pin"
                          maxLength={6}
                          type="number"
                          onChange={(e) => handlePin(e.target.value)}
                          value={pin}
                        />
                        <div
                          className={
                            pinError.length > 0
                              ? "chip-list"
                              : "chip-list hide-me"
                          }
                        >
                          <Chip
                            color="secondary"
                            label={pinError}
                            className={classes.chip}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="wristbandtag" className="form-label">
                          Wristband Tagid
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="wristbandtag"
                          onChange={(e) => setWristband(e.target.value)}
                          value={wristband}
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col-md-6">
                        <label htmlFor="selectlevel">
                          User Level <span className="require-field">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="selectlevel"
                          onChange={(e) => setLevel(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="user">User</option>
                          <option value="admin">Admin</option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="selectlevel">
                          Branch Location{" "}
                          <span className="require-field">*</span>
                        </label>
                        <SelectBranch />
                        {/* <select
                    className="form-control"
                    id="selectlevel"
                    onChange={(e) => setLocation(e.target.value)}
                  >
                    <option value=""></option>
                    {branchData.map((item, index) => {
                      const locData: locData = item;
                      return (
                        <>
                          <option value={locData.branch_id}>
                            {locData.branch_name} {"--"} {locData.city}
                          </option>
                        </>
                      );
                    })}
                  </select> */}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12">
                        <button
                          type="button"
                          onClick={formValidation}
                          className="btn btnColor btn-lg"
                        >
                          Register Staff
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Paper>
            </div>
          </div>
          {loading ? (
            <div className="jCentered">
              <Loader
                type="Oval"
                color="#3f51b5"
                height={70}
                width={70}
                timeout={10000} //10 secs
              />{" "}
            </div>
          ) : (
            ""
          )}

          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-sm-12 text-center">
              <h2 className="text-center jFontBold mt-3 mb-2">
                Registered Staff Details
              </h2>
              <List>
                <div
                  style={{
                    width: "auto",
                    margin: "0 auto",
                    textAlign: "center",
                  }}
                >
                  <Paper>
                    {getStaffDetails.loading && <p>Staff list is loading!</p>}
                    {getStaffDetails.data &&
                      getStaffDetails.data?.map((item, index) => {
                        const getData: locData = item;
                        return (
                          <ListItem key={getData.name}>
                            <ListItemAvatar>
                              <Avatar>
                                <PersonSharpIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={getData.name}
                              secondary={getData.branch_name}
                            />
                            <Button
                              id="basic-button"
                              aria-controls={
                                openMenu ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openMenu ? "true" : undefined}
                              onClick={(event) => handleMenu(event, getData)}
                            >
                              <img
                                src={sub_menu}
                                style={{ width: "30px", height: "30px" }}
                                alt="sub-menu"
                              />
                            </Button>
                          </ListItem>
                        );
                      })}
                  </Paper>
                </div>
              </List>
            </div>
          </div>
        </div>
        {open === true ? errorDialog() : ""}
      </div>
      <div>{editDialog === true ? modifyItem() : ""}</div>
      <div>{deleteDialog === true ? deleteItem() : ""}</div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={(e) => handleEditClick(e)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>Edit</div>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={(e) => handleDeleteClick(e)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText defaultValue="delete">
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>Delete</div>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onStatusChange(staffId)}>
          <ListItemIcon>
            {activeStatus === 0 ? (
              <img src={activate_av} width={25} alt="activate" />
            ) : (
              <img src={suspend_av} width={25} alt="suspend" />
            )}
          </ListItemIcon>
          <ListItemText>
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>
              {activeStatus === 0 ? "Activate" : "Deactivate"}
            </div>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
