import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import SessionData from "../../../../services/session-data";
import { useStyles } from "../../../Styles/mui-styles";
import UsedArticleFilterBar from "./used-article-filter-bar";
import Container from "@material-ui/core/Container";
import "../../../../styles/main-style.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Pagination from "@material-ui/lab/Pagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import UsedArticleInfoDialog from "./used-article-info-dialog";
import { ExportCSV } from "../../../Utils/ExportCsv";
import { Breadcrumbs, Grid, Typography } from "@material-ui/core";
import AppBarAndDrawer from "../../../Dashboard/AppBarAndDrawer";
const sd = new SessionData();
const API_URL = sd.getBaseURL();

//defining type for response
export type usedArticleType = {
  item_id: string;
  article_type: string;
  type: string;
  control_no: number;
  used_by: string;
  date_used: string;
  autoclave_name: string;
  bi_result: number | null;
  type5_result: number | null;
  temprature: number | null;
  pressure: number | null;
  load_finished_at: string;
  load_finished_by: string;
  cycle_no: number;
  total_cycles: number | null;
  cycles: number | null;
  pouch_articles: string | null | any;
};

type queryRowCount = {
  total_count: number;
};

export default function GetUsedArticleDetails() {
  const [currentURL, setCurrentURL] = useState(
    "&sort_by[ua.date_used]=DESC&limit=10"
  );
  const [response, setResponse] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(2);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(15);

  //filter parameters
  const [type, setType] = useState<String>("");
  const [usedBy, setUsedBy] = useState<String>("");
  const [dateUsed, setDateUsed] = useState<String | Date | null>("");
  const [startDate, setStartDate] = useState<String | Date | null>(null);
  const [endDate, setEndDate] = useState<String | Date | null>(null);
  const [controlNo, setControlNo] = useState<String | Number>("");
  const [dialogInfo, setDialogInfo] = useState({});
  /*React Dialog*/
  const [open, setOpen] = React.useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  var queryRowTotal: queryRowCount;
  var getData: usedArticleType;
  var filteredList: usedArticleType;
  // branch query
  let branchQuery: any = "";
  if (localStorage.getItem("branchId")) {
    let branchId: number = Number(localStorage.getItem("branchId"));
    branchQuery = `ap.branch_id=${branchId}`;
  }
  let queryURL = "";
  if (startDate !== null) {
    queryURL = `rg.type[LIKE]=${type}&sf1.username=${usedBy}&DATE(ua.date_used)[gte]=${startDate} &DATE(ua.date_used)[lte]=${endDate}&ua.control_no=${controlNo}&${branchQuery}&sort_by[ua.date_used]=DESC&limit=${limit}&offset=${offset}`;
  } else {

    if (endDate === null)
    {
      let date = new Date();
      setEndDate(date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate());
    }
    queryURL = `rg.type[LIKE]=${type}&sf1.username=${usedBy}&DATE(ua.date_used)[lte]=${endDate}&ua.control_no=${controlNo}&${branchQuery}&sort_by[ua.date_used]=DESC&limit=${limit}&offset=${offset}`;
  }
  const classes = useStyles();
  useEffect(() => {
    getUsedArticles();
  }, [
    type,
    usedBy,
    dateUsed,
    startDate,
    endDate,
    controlNo,
    limit,
    offset,
    page,
    currentURL,
  ]);

  //API Call
  const getUsedArticles = () => {
    axios
      .get(API_URL + "web-reports/usedarticlelog?" + queryURL, {
        method: "GET",
        headers: { Authorization: "Bearer " + sd.getClientToken() },
      })
      .then((result) => {
        if (result.status !== 200) throw new Error("Error");
        //storing response
        setResponse(result.data.res);
        //counting total rows
        result.data.res2.map((item, index) => {
          queryRowTotal = item;
          setTotalRows(queryRowTotal.total_count);
          setTotalPages(Math.ceil(queryRowTotal.total_count / limit));
        });
      })
      .catch((err) => {
        setTotalRows(0);
        setTotalPages(0);
        setResponse([]);
      });
  };

  //Handle the More info Click before opening dialog
  const handleInfoDialog = (e) => {
    response &&
      response.map((item, index) => {
        filteredList = item;
        if (index == e) {
          setDialogInfo(filteredList);
        }
      });
    setOpen(true);
  };

  //open info dialog
  const showInfoDialog = () => {
    return (
      <UsedArticleInfoDialog
        articleInfo={dialogInfo}
        open={true}
        parentCallback={handleCallback}
      />
    );
  };
  //callback from custom info dialog
  const handleCallback = (childData) => {
    setOpen(false);
  };

  //Filtering Data based on value inputs
  const handleFilterBarCallback = (
    type: String,
    usedBy: String,
    controlNo: String | Number,
    dateUsed: String | Date | null,
    startDate: String | Date | null,
    endDate: String | Date | null
  ) => {
    setPage(1);
    setOffset(0);
    setType(type);
    setUsedBy(usedBy);
    setControlNo(controlNo);
    setDateUsed(dateUsed);
    setStartDate(startDate);
    setEndDate(endDate);
    setFilterOpen(false);
  };

  //Pagination
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setTotalPages(Math.ceil(totalRows / limit));
    setPage(value);
    setOffset((value - 1) * limit);
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Used Articles</Typography>
        </Breadcrumbs>
        <Container maxWidth="lg" className="marginTop">
          <div className="row">
            <Grid item sm={8} xs={8}>
              <UsedArticleFilterBar parentCallback={handleFilterBarCallback} />
            </Grid>
            <Grid item sm={4} xs={4}>
              <ExportCSV csvData={response} fileName={"fileName"} />
            </Grid>
          </div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Article Name </TableCell>
                  <TableCell>Control#</TableCell>
                  <TableCell>Used By</TableCell>
                  <TableCell>Used Date</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {response &&
                  response.map((item, index) => {
                    getData = item;
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <div style={{ fontSize: "20px" }}>
                              {getData.type.charAt(0).toUpperCase() +
                                getData.type.slice(1)}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="cAvDate">{getData.control_no}</div>
                          </TableCell>
                          <TableCell>{getData.used_by}</TableCell>
                          <TableCell>
                            {getData.date_used.split("T")[0]}
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Button
                              className="cButton"
                              color="primary"
                              onClick={(e) =>
                                handleInfoDialog(e.currentTarget.value)
                              }
                              value={index}
                            >
                              view details
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px 0px 50px 0px",
              paddingBottom: "50px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </div>
        </Container>
        {open ? showInfoDialog() : ""}
      </div>
    </>
  );
}
