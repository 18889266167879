/* eslint-disable */
import React, { useEffect, useState } from "react";
import axios from "axios";
import SessionData from "../../services/session-data";
import { useStyles } from "../Styles/mui-styles";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import AppBarAndDrawer from "../Dashboard/AppBarAndDrawer";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const sd = new SessionData();
const API_URL = sd.getBaseURL();

type itemserviceType = {
  name: string;
  oos_reason: string;
  oos_date: Date;
  oos_by: string;
  bis_reason: string;
  bis_date: Date;
  bis_by: string;
};

export default function ServiceLog() {
  const [serviceList, setserviceList] = useState([]);
  const classes = useStyles();
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(1);
  const [limit, setLimit] = useState(15);
  const [offest, setOffset] = useState(0);
  let branchQuery: any = "";

  useEffect(() => {
    handleShowItemList();
    return () => {};
  }, []);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setOffset((value - 1) * limit);
  };

  const handleShowItemList = () => {
    if (localStorage.getItem("branchId")) {
      let branchId: number = Number(localStorage.getItem("branchId"));
      branchQuery = `st.branch_id=${branchId}`;
    }
    axios
      .get(API_URL + "web-reports/getservicelog?" + branchQuery, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
          "staff-auth": "Bearer " + sd.getStaffToken(),
        },
      })
      .then((result) => {
        if (result.status !== 200) throw new Error("Error");
        setserviceList(result.data);
        setTotalRows(result.data.length);
        setTotalPages(Math.ceil(result.data.length / limit));
      })
      .catch((error) => {});
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Service Logs</Typography>
        </Breadcrumbs>
        <Container maxWidth="lg" className="marginTop">
          <h2 className="jFontBold text-center m-4">Autoclave Service Log</h2>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">OOS Reason</TableCell>
                  <TableCell align="center">OOS By</TableCell>
                  <TableCell align="center">OOS Date</TableCell>
                  <TableCell align="center">BIS Reason</TableCell>
                  <TableCell align="center">BIS By</TableCell>
                  <TableCell align="center">BIS Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceList &&
                  serviceList.map((item, index) => {
                    const getData: itemserviceType = item;

                    {
                      if (index >= offest && index < limit * page) {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell align="center">{getData.name}</TableCell>
                            <TableCell align="center">
                              {getData.oos_reason}
                            </TableCell>
                            <TableCell align="center">
                              {getData.oos_by}
                            </TableCell>
                            <TableCell align="center">
                              {getData.oos_date != null
                                ? getData.oos_date.toString().split("T")[0]
                                : "_"}
                            </TableCell>
                            <TableCell align="center">
                              {getData.bis_reason}
                            </TableCell>
                            <TableCell align="center">
                              {getData.bis_by}
                            </TableCell>
                            <TableCell align="center">
                              {getData.bis_date != null
                                ? getData.bis_date.toString().split("T")[0]
                                : "_"}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    }
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px 0px 50px 0px",
              paddingBottom: "50px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </div>
        </Container>
      </div>
    </>
  );
}
