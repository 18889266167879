/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import SessionData from "../../../services/session-data";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../../styles/main-style.css";
import "react-tagsinput/react-tagsinput.css";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import PersonSharpIcon from "@material-ui/icons/PersonSharp";
import getEmailList from "../../Utils/FetchEmailList";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import suspend_av from "../../../images/suspend_autoclave.png";
import activate_av from "../../../images/activate_autoclave.png";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import sub_menu from "../../../images/sub_menu.png";
import ModifyUserEmail from "./ModifyUserEmail";
import DeleteUserEmail from "./DeleteUserEmail";
import {
  Breadcrumbs,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";

export type registeredEmails = {
  id: number;
  name: string;
  email_id: string;
  flag: number;
};

export default function RegisterEmail() {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const getEmail = getEmailList();
  const [callEffect, setCallEffect] = useState<boolean>(false);
  const [itemToModify, setItemToModify] = useState<any>([]);
  const [itemToDelete, setItemToDelete] = useState<any>([]);
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  // For the activate and deactivate of the user information
  const [activeStatus, setActiveStatus] = useState<number>();
  var [statusD, setStatusD] = useState("");
  var [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [ask, setAsk] = useState(false);
  let getData: registeredEmails;
  useEffect(() => {
    getEmail.request();
  }, [callEffect]);

  const handleClose = () => {
    setOpen(false);
    setCallEffect(!callEffect);
    setAsk(false);
  };
  // creating the session data variable
  const sd = new SessionData();
  //check if value is provided by user
  const formValidation = () => {
    if (name.length <= 0 || email.length <= 0) {
      setDMessage("Please provide name and email id");
      setOpen(true);
      return;
    }
    RegisterNewEmail();
  };

  const RegisterNewEmail = () => {
    const sd = new SessionData();
    setDialog("hidden");

    axios
      .post(
        sd.getBaseURL() + "web-register/addemails",
        {
          name: name,
          email: email,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Not able to Insert email");
          setOpen(true);
        }
        setDMessage("New Email Added");
        setOpen(true);
      })
      .catch((err) => {
        setDMessage("Not able to Insert email");
        setOpen(true);
      });
    //empty everything
    setName("");
    setEmail("");
  };
  // Changing the status of the user
  const handleUserStatus = () => {
    axios
      .put(
        sd.getBaseURL() + "web-register/status",
        {
          id: id,
          flag: statusD,
        },
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      )
      .then((data) => {
        if (data.status === 200) {
          setDMessage("Status Changed");
          setLoading(false);
          setOpen(true);
        }
      })
      .catch((err) => {
        setDMessage("Error Changing Status");
        setLoading(false);
        setOpen(true);
      });
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // Handling the status change call
  const statusChangeCall = () => {
    setAsk(false);
    handleUserStatus();
  };
  const handleMenu = (event, getItemData: registeredEmails) => {
    setAnchorEl(event.currentTarget);
    setItemToModify(getItemData);
    setItemToDelete(getItemData);
    setActiveStatus(getItemData.flag);
    setUserId(getItemData.id);
  };

  // Handling the edit click
  const handleTheClick = (e) => {
    setEditDialog(true);
    setAnchorEl(null);
  };

  // Handle Delete Click
  const handleDeleteClick = (e) => {
    setDeleteDialog(true);
    setAnchorEl(null);
  };
  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Email Information</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  // To change the flag of the user to activate and deactivate
  const onStatusChange = (e) => {
    setAnchorEl(null);
    getEmail &&
      getEmail.data.map((item, index) => {
        getData = item;
        if (getData.id == e) {
          if (getData.flag === 1) {
            setId(getData.id.toString());
            setStatusD("0");
            setDMessage("Are you sure you want to Deactivate the User?");
            setAsk(true);
          } else if (getData.flag === 0) {
            setId(getData.id.toString());
            setStatusD("1");
            setDMessage("Are you sure you want to Activate the User?");
            setAsk(true);
          }
        }
      });
  };

  // Modifying the item
  const modifyItem = () => {
    return (
      <ModifyUserEmail
        editOpen={true}
        data={itemToModify}
        parentCallback={handleModifyDialog}
      />
    );
  };
  // Deleting the item

  const deleteItem = () => {
    return (
      <DeleteUserEmail
        deleteOpen={true}
        data={itemToDelete}
        parentCallback={handleDeleteDialog}
      />
    );
  };

  // Modify Dialog handle
  const handleModifyDialog = (message: any) => {
    setDMessage(message);
    setOpen(true);
    setEditDialog(false);
    setAnchorEl(null);
  };
  // Delete Dialog handle
  const handleDeleteDialog = (message: any) => {
    setDMessage(message);
    setOpen(true);
    setDeleteDialog(false);
    setAnchorEl(null);
  };
  // Question Dialouge
  const questionDialog = () => {
    return (
      <Dialog
        open={ask}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "20px" }}
            id="alert-dialog-description"
          >
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ fontSize: "20px", color: "red" }}
            color="secondary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            style={{ fontSize: "20px" }}
            onClick={statusChangeCall}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Register Emails</Typography>
        </Breadcrumbs>

        <div
          className="container dFlex dFlexCol"
          style={{ width: "80%", height: "80%", justifyContent: "center" }}
        >
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-sm-12 text-center">
              <h2 className="text-center jFontBold">Add New Email id</h2>
              <Paper className="jPadding box-shadow">
                <form>
                  <div className="form-group">
                    <label htmlFor="autoclaveName">Name</label>
                    <input
                      placeholder="Name"
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      id="autoclaveName"
                    ></input>
                  </div>
                  <div className="form-group">
                    <label htmlFor="autoclaveName">Email</label>
                    <input
                      placeholder="Email"
                      type="email"
                      className="form-control"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      id="autoclaveName"
                    ></input>
                  </div>

                  <button
                    type="button"
                    onClick={formValidation}
                    className="btn btnColor btn-lg"
                  >
                    Submit
                  </button>
                </form>
                <div className="row"></div>
              </Paper>
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-lg-6 col-sm-12 text-center">
            <h2 className="text-center jFontBold mt-3 mb-2">
              Registered Emails
            </h2>
            <List>
              <div
                style={{ width: "auto", margin: "0 auto", textAlign: "center" }}
              >
                <Paper>
                  {getEmail.loading && <p>Emails are loading!</p>}
                  {getEmail.data &&
                    getEmail.data?.map((item, index) => {
                      const getData: registeredEmails = item;
                      return (
                        <ListItem key={getData.id}>
                          <ListItemAvatar>
                            <Avatar>
                              <PersonSharpIcon />
                            </Avatar>
                          </ListItemAvatar>
                          {getData.flag === 0 ? (
                            <ListItemText
                              primaryTypographyProps={{
                                style: { color: "#B22222" },
                              }}
                              primary={getData.name}
                              secondary={getData.email_id}
                            />
                          ) : (
                            <ListItemText
                              primaryTypographyProps={{
                                style: { color: "#006400" },
                              }}
                              primary={getData.name}
                              secondary={getData.email_id}
                            />
                          )}
                          <Button
                            id="basic-button"
                            aria-controls={openMenu ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMenu ? "true" : undefined}
                            onClick={(event) => handleMenu(event, getData)}
                          >
                            <img
                              src={sub_menu}
                              style={{ width: "30px", height: "30px" }}
                              alt="sub-menu"
                            />
                          </Button>
                        </ListItem>
                      );
                    })}
                </Paper>
              </div>
            </List>
          </div>
        </div>

        {open === true ? errorDialog() : ""}
      </div>
      <div> {ask === true ? questionDialog() : ""}</div>
      <div>{editDialog === true ? modifyItem() : ""}</div>
      <div>{deleteDialog === true ? deleteItem() : ""}</div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={(e) => handleTheClick(e)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>Edit</div>
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleDeleteClick(e);
          }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText defaultValue="delete">
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>Delete</div>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onStatusChange(userId)}>
          <ListItemIcon>
            {activeStatus === 0 ? (
              <img src={activate_av} width={25} alt="activate" />
            ) : (
              <img src={suspend_av} width={25} alt="suspend" />
            )}
          </ListItemIcon>
          <ListItemText>
            <div style={{ fontSize: "16px", marginLeft: "10px" }}>
              {activeStatus === 0 ? "Activate" : "Deactivate"}
            </div>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
