import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import NewUseArticle from "./new-use-article";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@material-ui/core";

export default function UseArticles() {
  const [storeTags, setStoreTags] = useState<String[]>([]);

  useEffect(() => {
    getArticleInfo();
  }, []);

  const getArticleInfo = () => {
    axios
      .get("https://beacontree.dentaltracking.com/api/v1/logs", {
        method: "GET",
        // auth: sd.LMLoginData(),
        auth: {
          username: "username",
          password: "password",
        },

        headers: {
          "Content-Type": "application-json",
          Authorization:
            "Basic ZHRzcmVzdGFwaTpmdk81TzF1aFdINUFKZ0ZKWlZnWkZHR1A0TURYMkoweg==",
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("Error loggin in");
        return <div></div>;
      })
      .catch((error) => {
        return false;
      });
  };

  const handleTagReading = useCallback(
    (tag) => {
      setStoreTags((storeTags) => [...storeTags!, tag]);
    },
    [storeTags]
  );

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Used Articles</Typography>
        </Breadcrumbs>
        <NewUseArticle parentCallback={handleTagReading} />
        <div className="container">
          {storeTags &&
            storeTags!.map((item, index) => {
              return <div className="row">{item}</div>;
            })}
        </div>
      </div>
    </>
  );
}
