import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import AppBarAndDrawer from "../Dashboard/AppBarAndDrawer";
import { Breadcrumbs, Typography, Paper } from "@material-ui/core";
import Loader from "react-loader-spinner";
import SelectBranch from "../Utils/SelectBranch";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import SessionData from "../../services/session-data";

export default function PrintLabel() {
  const [dMessage, setDMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enterId, setEnterId] = useState("");
  const [getInfo, setGetInfo] = useState<boolean>(false);
  const [errorColor, setErrorColor] = useState(true);
  const sd = new SessionData();
  const formValidation = () => {
    if (enterId == null) {
      setDMessage("Please Scan the required Item(s)");
      setOpen(true);
      return;
    } else if (enterId.length <= 0) {
      setDMessage("Please Scan the required Item(s)");
      setOpen(true);
      return;
    }
    printLabel();
    setLoading(true);
  };

  const printLabel = async () => {
    await axios
      .get(sd.getBaseURL() + `web-standalone/print-label/${enterId}/`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
          "staff-auth": "Bearer " + localStorage.getItem("staffAuthToken"),
        },
      })
      .then(async (result) => {
        if (result.data.status === 200) {
          setLoading(false);
          setGetInfo(false);
          setErrorColor(true);
          setDMessage("Found the required Information!");
          setOpen(true);
          setEnterId("");
        }
      })
      .catch((error) => {
        setEnterId("");
        setGetInfo(false);
        setLoading(false);
        setErrorColor(true);
        setDMessage("Scan Item(s) not Found");
        setOpen(true);
      });
  };

  const printBarcode = async (serial) => {
    try {
      // Create a new instance of the object
      const browserPrint = new ZebraBrowserPrintWrapper();

      // Select default printer
      const defaultPrinter = await browserPrint.getDefaultPrinter();

      // Set the printer
      browserPrint.setPrinter(defaultPrinter);

      // Check printer status
      const printerStatus = await browserPrint.checkPrinterStatus();

      // Check if the printer is ready
      if (printerStatus.isReadyToPrint) {
        // ZPL script to print a simple barcode
        const zpl = `
              ^XA
              ^PW400
              ^LL200
              ^CF0,20
              
              // Add "REPROLOG" as vertical on the left side
              ^FO10,20^A0B,20,30^FDREPROLOG^FS
              
              // Add the rest of the content
              ^FO30,20^FDDate: 02-15-2024 12:46:32 PM^FS
              ^FO30,40^FDExpiry: 02-10-2024^FS
              ^FO30,60^FDBravo 17V Cycle #210^FS
              ^FO30,80^FDSurgical Kit - 12321^FS
              ^FO30,100^FDControl #343^FS
              ^FO30,120^FDJane Michales^FS
              
              ^FO220,40^BQN,2,3^FDQA,YourDataHere^FS
              ^XZ            
              `;

        browserPrint.print(zpl);
      } else {
        console.log("Error/s", printerStatus.errors);
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Print Label</Typography>
        </Breadcrumbs>
        <div
          className="container dFlex dFlexCol"
          style={{ width: "100%", height: "80%", justifyContent: "center" }}
        >
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-sm-12 text-center">
              <h2 className="text-center jFontBold mt-3 mb-3">Print Label</h2>
              <Paper className="jPadding box-shadow">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group">
                    <label htmlFor="readerName">Enter Control Number</label>
                    <input
                      autoFocus
                      placeholder="Enter Control Number"
                      type="text"
                      className="form-control"
                      required
                      id="controlNumber"
                      autoComplete="false"
                    ></input>
                  </div>
                  {localStorage.getItem("roleId") === "1" ? (
                    <div className="form-group">
                      <label htmlFor="location">Select Branch Name</label>
                      <SelectBranch />
                    </div>
                  ) : (
                    ""
                  )}
                  <button type="button" className="btn btnColor btn-lg">
                    Print Label
                  </button>
                </form>
              </Paper>
            </div>
          </div>
          {loading ? (
            <div className="jCentered">
              <Loader
                type="Oval"
                color="#3f51b5"
                height={70}
                width={70}
                timeout={10000} //10 secs
              />{" "}
            </div>
          ) : (
            ""
          )}
          {getInfo ? (
            <div className="row justify-content-md-center">
              <div className="col-lg-8 col-sm-12 text-center">
                <h2 className="text-center jFontBold mt-3 mb-1">Status</h2>
                <span className="jFontBold mb-2">
                  (Please scan one item at a time)
                </span>
                <Paper className="jPadding box-shadow">
                  <div className="row">
                    <div className="form-group col-md-6">
                      <h5 style={{ fontWeight: "bold" }}>Item Name</h5>
                      <span
                        className="form-control"
                        style={{ fontWeight: "bold", color: "#052CA3" }}
                      >
                        {"itemName"}
                      </span>
                    </div>
                  </div>
                </Paper>
                {loading ? (
                  <div className="jCentered">
                    <Loader
                      type="Oval"
                      color="#3f51b5"
                      height={70}
                      width={70}
                      timeout={10000} //10 secs
                    />{" "}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
