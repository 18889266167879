/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import SessionData from "../../../services/session-data";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../../../styles/main-style.css";
import "react-tagsinput/react-tagsinput.css";
import Paper from "@material-ui/core/Paper";
import getAutoclavesList from "../../Utils/FetchAutoclaveList";
import AppBarAndDrawer from "../../Dashboard/AppBarAndDrawer";
import Breadcrumbs from "@mui/material/Breadcrumbs/Breadcrumbs";
import { Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
export type autoclaveList = {
  id: number;
  autoclave_name: string;
  autoclave_model: string;
  autoclave_manufacturer: string;
  serial_no: string;
  bowie_dick: string;
  cycle_name: string;
  temperature_celcius: number;
  pressure: string;
  duration: number;
  drying_duration: number;
};

export default function RegisterNewAutoclave() {
  const [autoclaveName, setAutoclaveName] = useState<string>("");
  const [autoclaveOpName, setAutoclaveOpName] = useState<string>("");
  const [autoclaveModel, setAutoclaveModel] = useState<string>("");
  const [autoclaveManufacturer, setAutoclaveManufacturer] =
    useState<string>("");
  const [serialNo, setSerialNo] = useState<string>("");
  const [bowieDick, setBowieDick] = useState<string>("");

  const [inputFields, setInputFields] = useState<any>([
    {
      cycleName: [],
      temperature: [],
      pressure: [],
      duration: [],
      dryingDuration: [],
      unit: "",
    },
  ]);
  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dMessage, setDMessage] = useState("");
  const [branches, setBranches] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [callEffect, setCallEffect] = useState<boolean>(false);
  const getAutoList = getAutoclavesList();

  useEffect(() => {
    getAutoList.request();
  }, [callEffect]);

  let cycleName: string[] = [];
  let temperatureCelcius: string[] = [];
  let pressure: string[] = [];
  let duration: string[] = [];
  let dryingDuration: string[] = [];

  const handleClose = () => {
    setOpen(false);
    setCallEffect(!callEffect);
  };
  // To add more cycle types
  const addFields = () => {
    let newField = {
      cycleName: "",
      temperature: "",
      pressure: "",
      duration: "",
      dryingDuration: "",
      unit: "",
    };

    setInputFields([...inputFields, newField]);
  };

  //   On article name and tag, change, adding data to input fields
  const handleCycletypeChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  // To remove cycle types
  //   To remove the article tag/instrument
  const removeArticleData = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };
  //check if value is provided by user
  const formValidation = () => {
    if (
      autoclaveName.length <= 0 ||
      autoclaveModel.length <= 0 ||
      autoclaveManufacturer.length <= 0 ||
      bowieDick.length <= 0
    ) {
      setDMessage("Please provide all the information");
      setOpen(true);
      return;
    } else {
      RegisterNewAutoclave();
    }
  };

  const ChechExistingAutoclave = () => {
    getAutoList.data?.map((item, index) => {
      const getData: autoclaveList = item;
      console.log(
        getData.autoclave_name + getData.autoclave_model,
        getData.serial_no
      );
      console.log(autoclaveName + autoclaveModel + serialNo);

      if (
        (autoclaveName === getData.autoclave_name &&
          autoclaveModel === getData.autoclave_model &&
          serialNo === getData.serial_no) ||
        serialNo === "null"
      ) {
        setDMessage("Autoclave already exists in the database!!");
        setOpen(true);

        setAutoclaveName("");
        setAutoclaveOpName("");
        setAutoclaveModel("");
        setAutoclaveManufacturer("");
        setSerialNo("");
        setBowieDick("");
        setInputFields([
          {
            cycleName: [],
            temperature: [],
            pressure: [],
            duration: [],
            dryingDuration: [],
            unit: "",
          },
        ]);

        return true;
      }
    });
  };
  const RegisterNewAutoclave = () => {
    inputFields.forEach((item, indexes) => {
      let setPressure: string = item["pressure"] + " " + item["unit"];
      cycleName.push(item["cycleName"]);
      temperatureCelcius.push(item["temperature"]);
      pressure.push(setPressure);
      duration.push(item["duration"]);
      dryingDuration.push(item["dryingDuration"]);
    });

    const sd = new SessionData();
    setDialog("hidden");
    axios
      .post(
        sd.getBaseURL() + "web-register/newautoclave",
        {
          autoclave_name: autoclaveName,
          autoclave_model: autoclaveModel,
          autoclave_manufacturer: autoclaveManufacturer,
          serial_no: serialNo || "null",
          bowie_dick: bowieDick,
          autoclave_opname: autoclaveOpName,
          cycle_name: cycleName,
          temperature_celcius: temperatureCelcius,
          pressure: pressure,
          duration: duration,
          drying_duration: dryingDuration,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Not able to Register Branch");
          setOpen(true);
        }
        setDMessage(
          autoclaveName +
            " , Model - " +
            autoclaveModel +
            " Autoclave has been Registered!!"
        );
        setOpen(true);
      })
      .catch((err) => {
        setDMessage("Not able to Register Autoclave");
        setOpen(true);
      });
    //empty everything
    setAutoclaveName("");
    setAutoclaveOpName("");
    setAutoclaveModel("");
    setAutoclaveManufacturer("");
    setSerialNo("");
    setBowieDick("");
    setInputFields([
      {
        cycleName: [],
        temperature: [],
        pressure: [],
        duration: [],
        dryingDuration: [],
        unit: "",
      },
    ]);
  };

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Information</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="textPrimary">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Register New Autoclave</Typography>
        </Breadcrumbs>
        <div
          className="container dFlex dFlexCol text-center"
          style={{
            width: "80%",
            justifyContent: "center",
            margin: "auto",
            paddingBottom: "50px",
          }}
        >
          {/* {editMenu.createEditMenu()} */}
          <div className="row justify-content-md-center">
            <div className="col-lg-12 col-sm-12 text-center">
              <h2 className="text-center jFontBold mt-3 mb-2">
                Add New Autoclave
              </h2>
              <Paper className="jPadding box-shadow">
                <form>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label htmlFor="autoclaveName">
                        Autoclave Name <span className="require-field">*</span>
                      </label>
                      <input
                        placeholder="Autoclave Name"
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) => setAutoclaveName(e.target.value)}
                        value={autoclaveName}
                        id="autoclaveName"
                      ></input>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="autoclaveName">
                        Autoclave Optional Name
                      </label>
                      <input
                        placeholder="Autoclave Optional Name"
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) => setAutoclaveOpName(e.target.value)}
                        value={autoclaveOpName}
                        id="autoclaveOpName"
                      ></input>
                    </div>

                    <div className="col-md-6 form-group">
                      <label htmlFor="autoclaveModel">
                        Autoclave Model <span className="require-field">*</span>
                      </label>
                      <input
                        placeholder="Autoclave Model"
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) => setAutoclaveModel(e.target.value)}
                        value={autoclaveModel}
                        id="autoclaveModel"
                      ></input>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="autoclaveManufacturer">
                        Autoclave Manufacturer{" "}
                        <span className="require-field">*</span>
                      </label>
                      <input
                        placeholder="Autoclave Manufacturer"
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) =>
                          setAutoclaveManufacturer(e.target.value)
                        }
                        value={autoclaveManufacturer}
                        id="autoclaveManufacturer"
                      ></input>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="serialNo">
                        Serial No. <span className="require-field">*</span>
                      </label>
                      <input
                        placeholder="Serial No."
                        type="text"
                        className="form-control"
                        onChange={(e) => setSerialNo(e.target.value)}
                        value={serialNo}
                        id="serialNo"
                      ></input>
                    </div>
                    <div className="col-md-6 form-group">
                      <label htmlFor="bowieDick">
                        Is The Autoclave a BowieDick?{" "}
                        <span className="require-field">*</span>
                      </label>
                      <select
                        className="form-control"
                        id="selectAutoclave"
                        value={bowieDick}
                        onChange={(e) => setBowieDick(e.target.value)}
                      >
                        <option>--Select--</option>
                        <option
                          style={{
                            margin: "100px",
                            color: "#0a9396",
                            fontSize: "20px",
                            padding: "20px",
                          }}
                        >
                          Yes
                        </option>
                        <option
                          style={{
                            margin: "100px",
                            color: "#0a9396",
                            fontSize: "20px",
                            padding: "20px",
                          }}
                        >
                          No
                        </option>
                      </select>
                    </div>
                    <div className="container">
                      <label htmlFor="additionalInfo" className="form-label">
                        Please Enter the Cycle types for the new registered
                        autoclave.
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "normal",
                            color: "#FFC400",
                          }}
                        >
                          <br />
                          (Please enter first cycle as initial cycle!)
                        </span>
                      </label>
                      {inputFields.map((input, index) => {
                        return (
                          <div key={index}>
                            <hr />
                            <div className="row mb-3">
                              <div className="col-md-12 row form-group">
                                <div
                                  className="col-md-3 form-group"
                                  // style={{ textAlign: "left" }}
                                >
                                  <label
                                    htmlFor="cycleTypeName"
                                    className="mt-2"
                                  >
                                    Cycle Type Name{" "}
                                    <span className="require-field">*</span>
                                  </label>
                                </div>
                                <div className="col-md-3 form-group">
                                  <input
                                    required
                                    name="cycleName"
                                    placeholder="Cycle Type Name"
                                    type="text"
                                    className="form-control"
                                    id="cycleTypeName"
                                    value={input.cycleName}
                                    onChange={(e) =>
                                      handleCycletypeChange(index, e)
                                    }
                                  ></input>
                                </div>
                                <div className="col-md-3 form-group">
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={() => removeArticleData(index)}
                                  >
                                    <DeleteIcon />
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-2 form-group">
                                <label htmlFor="temperatureCelcius">
                                  Temperature (C){" "}
                                  <span className="require-field">*</span>
                                </label>
                                <input
                                  required
                                  name="temperature"
                                  placeholder="Temperature (C)"
                                  type="text"
                                  className="form-control"
                                  value={input.temperature}
                                  onChange={(e) =>
                                    handleCycletypeChange(index, e)
                                  }
                                  id="temperatureCelcius"
                                ></input>
                              </div>
                              <div className="col-md-2 form-group">
                                <label htmlFor="pressure">
                                  Pressure{" "}
                                  <span className="require-field">*</span>
                                </label>
                                <input
                                  required
                                  name="pressure"
                                  placeholder="Pressure"
                                  type="text"
                                  className="form-control"
                                  value={input.pressure}
                                  onChange={(e) =>
                                    handleCycletypeChange(index, e)
                                  }
                                  id="pressure"
                                ></input>
                              </div>
                              <div className="col-md-2 form-group">
                                <label htmlFor="unit">
                                  Unit <span className="require-field">*</span>
                                </label>
                                <select
                                  required
                                  name="unit"
                                  className="form-control"
                                  id="selectAutoclave"
                                  value={input.unit}
                                  onChange={(e) =>
                                    handleCycletypeChange(index, e)
                                  }
                                >
                                  <option>--Select--</option>
                                  <option
                                    style={{
                                      margin: "100px",
                                      color: "#0a9396",
                                      fontSize: "20px",
                                      padding: "20px",
                                    }}
                                  >
                                    psi
                                  </option>
                                  <option
                                    style={{
                                      margin: "100px",
                                      color: "#0a9396",
                                      fontSize: "20px",
                                      padding: "20px",
                                    }}
                                  >
                                    bar
                                  </option>
                                </select>
                              </div>
                              <div className="col-md-3 form-group">
                                <label htmlFor="duration">
                                  Duration (min){" "}
                                  <span className="require-field">*</span>
                                </label>
                                <input
                                  required
                                  name="duration"
                                  placeholder="Duration (min)"
                                  type="number"
                                  className="form-control"
                                  value={input.duration}
                                  onChange={(e) =>
                                    handleCycletypeChange(index, e)
                                  }
                                  id="duration"
                                ></input>
                              </div>
                              <div className="col-md-3 form-group">
                                <label htmlFor="dryingDuration">
                                  Drying Duration (min){" "}
                                  <span className="require-field">*</span>
                                </label>
                                <input
                                  required
                                  name="dryingDuration"
                                  placeholder="Drying Duration (min)"
                                  type="number"
                                  className="form-control"
                                  value={input.dryingDuration}
                                  onChange={(e) =>
                                    handleCycletypeChange(index, e)
                                  }
                                  id="dryingDuration"
                                ></input>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="row container">
                        <button
                          type="button"
                          className="btn btn-success btn-md"
                          onClick={addFields}
                        >
                          Add Cycle Type
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={formValidation}
                    className="btn btnColor btn-md"
                  >
                    Submit
                  </button>
                </form>
              </Paper>
            </div>
          </div>
        </div>
        {open === true ? errorDialog() : ""}
      </div>
    </>
  );
}
