import React, { useEffect, useState } from "react";
import axios from "axios";
import SessionData from "../../services/session-data";
import {
  Avatar,
  Breadcrumbs,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "react-loader-spinner";
import Button from "@material-ui/core/Button";
import AppBarAndDrawer from "../Dashboard/AppBarAndDrawer";
import { Link } from "react-router-dom";
import SelectBranch from "../Utils/SelectBranch";
import useArticleImage from "../../images/used-articles.png";
import DeleteIcon from "@material-ui/icons/Delete";

export type instrumentDataType = {};

export default function UseInstrumentStatus() {
  const rfidEnabled = localStorage.getItem("rfid_enabled");
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [enterId, setEnterId] = useState("");
  const [errorColor, setErrorColor] = useState(true);
  const [itemName, setItemName] = useState<string>("");
  const [ask, setAsk] = useState(false);
  const [status, setStatus] = useState<string>("");

  const [controlNo, setControlNo] = useState<null | number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [getInfo, setGetInfo] = useState<boolean>(false);
  const [instrumentData, setInstrumentData] = useState<any>([]);

  // For discarding item
  const [articleStatus, setArticleStatus] = useState<string>("");
  const [activityId, setActivityId] = useState<number>(0);
  const [getArticleInfoEndPoint, setGetArticleInfoEndPoint] =
    useState<string>("");
  const [checkStatusEndPoint, setCheckStatusEndPoint] = useState<string>("");
  // Payload Data for confirm instrument
  let controlNos: any = [];
  let articleIds: any = [];
  let autoclaveIds: any = [];
  let articleTagIds: any = [];

  const sd = new SessionData();
  let branch_id: any = "";
  if (localStorage.getItem("roleId") !== "1") {
    branch_id = localStorage.getItem("branchId");
  } else {
    branch_id = localStorage.getItem("branchLocation");
  }
  const handleClose = () => {
    setOpen(false);
    setAsk(false);
  };

  const formValidation = () => {
    if (enterId == null) {
      setDMessage("Please Scan the required Item(s)");
      setOpen(true);
      return;
    } else if (enterId.length <= 0) {
      setDMessage("Please Scan the required Item(s)");
      setOpen(true);
      return;
    }
    getArticleInfo();
    setLoading(true);
  };

  // First to get the article information

  const getArticleInfo = async () => {
    // To check the state of RFID to set the endpoint
    if (rfidEnabled === "0") {
      setGetArticleInfoEndPoint("get-article-info-using-controlno");
      setCheckStatusEndPoint("check-status-using-control-no");
    } else {
      setGetArticleInfoEndPoint("get-article-info");
      setCheckStatusEndPoint("check-status");
    }
    await axios
      .get(
        sd.getBaseURL() +
          `web-standalone/${getArticleInfoEndPoint}/${enterId}/${branch_id}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + localStorage.getItem("staffAuthToken"),
          },
        }
      )
      .then(async (result) => {
        // console.log(result);
        if (result.data.status === 205) {
          setEnterId("");
          setLoading(false);
          // setGetInfo(false);
          setErrorColor(true);
          setDMessage(
            "Sorry, Scan item was not found, Please Check Tag Id and Branch Location!"
          );
          setOpen(true);
        } else {
          if (
            result.data.latest_activity_id !== null &&
            result.data.latest_activity_id === 20761
          ) {
            if (
              result.data.article_type === "biological-indicator" ||
              result.data.article_type === "class-2" ||
              result.data.article_type === "class-5"
            ) {
              setEnterId("");
              setLoading(false);
              // setGetInfo(false);
              setErrorColor(true);
              setDMessage(
                "B1 and C5 can't be used as instrunments on patients!"
              );
              setOpen(true);
            } else {
              await axios
                .get(
                  sd.getBaseURL() +
                    `web-standalone/${checkStatusEndPoint}/${enterId}`,
                  {
                    method: "GET",
                    headers: {
                      Authorization: "Bearer " + sd.getClientToken(),
                      "staff-auth":
                        "Bearer " + localStorage.getItem("staffAuthToken"),
                    },
                  }
                )
                .then(async (res) => {
                  // console.log(res.data);
                  let expiryDays = 30 - (res.data.load_finished_at % 365);
                  if (res.data.status === 205) {
                    // setGetInfo(false);
                    setErrorColor(true);
                    setDMessage("This item is held for the B1 test result!");
                    setLoading(false);
                    setOpen(true);
                  } else {
                    if (expiryDays < 1) {
                      setArticleStatus("ready_to_process");
                      setActivityId(19407);
                      await axios.post(
                        sd.getBaseURL() +
                          `web-standalone/discard-item/${enterId}`,
                        {
                          removal_reason: "item expired",
                          loaded_id: res.data.loaded_id,
                          article_status: "ready_to_process",
                          latest_activity: 19407,
                          article_id: res.data.id,
                        },
                        {
                          method: "POST",
                          headers: {
                            Authorization: "Bearer " + sd.getClientToken(),
                            "staff-auth":
                              "Bearer " +
                              localStorage.getItem("staffAuthToken"),
                          },
                        }
                      );
                      setEnterId("");
                      setErrorColor(true);
                      setDMessage(
                        "This item is expired and has been discarded, please reprocess before using it again!"
                      );
                      setLoading(false);
                      setOpen(true);
                    } else {
                      if (res.data.load_finished_at > 30) {
                        setErrorColor(true);
                        setDMessage(
                          "Found the required Information, but the item is sterillized for more than 30 days, please reprocess before using it again!"
                        );
                        setEnterId("");
                        setStatus("Expired!");
                        setOpen(true);
                      } else {
                        setEnterId("");
                        setErrorColor(false);
                        setDMessage("Found the required Information!");
                        setStatus("Certified");
                      }
                      setLoading(false);
                      setGetInfo(true);
                      setItemName(result.data.type);
                      setControlNo(res.data.control_no);
                      let newData = {
                        controlNo: res.data.control_no,
                        itemName: result.data.type,
                        autoclaveId: res.data.process_id,
                        tagId: res.data.article_tag_id,
                        articleId: res.data.id,
                      };

                      setInstrumentData([...instrumentData, newData]);
                    }
                  }
                })
                .catch((err) => {
                  setEnterId("");
                  // setGetInfo(false);
                  setLoading(false);
                  setErrorColor(true);
                  setDMessage("Scan Item(s) not Found");
                  setOpen(true);
                });
            }
          } else if (result.data.latest_activity_id === 19358) {
            setEnterId("");
            setLoading(false);
            // setGetInfo(false);
            setErrorColor(true);
            setDMessage("Heads Up!!, this item supposed to be in autoclave");
            setOpen(true);
          } else {
            setEnterId("");
            setLoading(false);
            // setGetInfo(false);
            setErrorColor(true);
            setDMessage("Item is not sterillized, reporcess before use!");
            setOpen(true);
          }
        }
      })
      .catch((err) => {
        setEnterId("");
        // setGetInfo(false);
        setLoading(false);
        setErrorColor(true);
        setDMessage("Scan Item(s) not Found");
        setOpen(true);
      });
  };

  //-------------------------------
  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Information</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            color={errorColor ? "error" : "primary"}
            style={{ fontWeight: "bold" }}
          >
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  //-------------------------------
  // Question Dialouge
  const questionDialog = () => {
    return (
      <Dialog
        open={ask}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Attention</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ fontSize: "20px" }}
            id="alert-dialog-description"
          >
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ fontSize: "20px", color: "red" }}
            color="secondary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            style={{ fontSize: "20px" }}
            onClick={callConfirmUse}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const confirmUse = () => {
    setDMessage("Are you sure you want to continue to use?");
    setAsk(true);
  };

  const callConfirmUse = async () => {
    setAsk(false);
    instrumentData.forEach((obj: any) => {
      controlNos.push(obj["controlNo"]);
      autoclaveIds.push(obj["autoclaveId"]);
      articleIds.push(obj["articleId"]);
      articleTagIds.push(obj["tagId"]);
    });

    await axios
      .post(
        sd.getBaseURL() + `web-standalone/instrument-item/confirm-use`,
        {
          article_ids: articleIds,
          articleTagIds: articleTagIds,
          processIds: autoclaveIds,
          controlNos: controlNos,
          article_status: "ready_to_process",
          latest_activity: 19359,
          // Passing static reader location id, need to set dynamic. for blue_skies, reader_location will be 1 and for IPac, reader location will  be 10
          readerLocation: 1,
          branch_id: branch_id,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + localStorage.getItem("staffAuthToken"),
          },
        }
      )
      .then((result) => {
        if (result.data.status === 205) {
          setErrorColor(true);
          setDMessage("Soemthing went wrong!");
          setOpen(true);
          setLoading(false);
          setGetInfo(false);
        } else {
          setErrorColor(false);
          setDMessage("Instrument(s) are in use!");
          setOpen(true);
          setLoading(false);
          setGetInfo(false);
        }
        setItemName("");
        setControlNo(0);
        setInstrumentData([]);
      })
      .catch((err) => {
        setEnterId("");
        setItemName("");
        setControlNo(0);
        setInstrumentData([]);
        setErrorColor(true);
        setDMessage("Something went wrong!");
        setOpen(true);
        setLoading(false);
        setGetInfo(false);
      });
  };
  useEffect(() => {
    if (instrumentData.length === 0) {
      setGetInfo(false);
    }
  }, [instrumentData]);
  const removeItem = (data: any) => {
    let newInstrumentList = instrumentData.filter(
      (item) => item.controlNo !== data.controlNo
    );
    setInstrumentData(newInstrumentList);
  };
  const handleTag = (e) => {
    e.preventDefault();
    setEnterId(e.target.value);
  };
  return (
    <>
      <AppBarAndDrawer />
      <div style={{ marginLeft: "260px" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/index">
            Home
          </Link>
          <Typography color="textPrimary">Use Instrument</Typography>
        </Breadcrumbs>
        <div
          className="container dFlex dFlexCol"
          style={{ width: "100%", height: "80%", justifyContent: "center" }}
        >
          <div className="row justify-content-md-center">
            <div className="col-lg-6 col-sm-12 text-center">
              <h2 className="text-center jFontBold mt-3 mb-3">
                Use Instrument
              </h2>
              <Paper className="jPadding box-shadow">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group">
                    <label htmlFor="scanItem">
                      {rfidEnabled === "0"
                        ? "Enter Control No."
                        : "Scan Item/ Instrument"}
                    </label>
                    <input
                      autoFocus
                      placeholder={
                        rfidEnabled === "0"
                          ? "Enter Control No."
                          : "Scan Item/ Instrument"
                      }
                      type="text"
                      className="form-control"
                      required
                      id="scanItem"
                      onChange={(e) => handleTag(e)}
                      value={enterId}
                      autoComplete="false"
                    ></input>
                  </div>

                  {/* <input
                    placeholder="Scan Item/ Instrument"
                    type="hidden"
                    className="form-control"
                    required
                    id="scanItem"
                    value=""
                    autoComplete="false"
                  ></input> */}

                  {localStorage.getItem("roleId") === "1" ? (
                    <div className="form-group">
                      <label htmlFor="location">Select Branch Name</label>
                      <SelectBranch />
                    </div>
                  ) : (
                    ""
                  )}

                  <button
                    type="button"
                    onClick={formValidation}
                    className="btn btnColor btn-lg"
                  >
                    Check Status
                  </button>
                </form>
              </Paper>
            </div>
          </div>
          {loading ? (
            <div className="jCentered">
              <Loader
                type="Oval"
                color="#3f51b5"
                height={70}
                width={70}
                timeout={10000} //10 secs
              />{" "}
            </div>
          ) : (
            ""
          )}

          {getInfo ? (
            <div className="row justify-content-md-center">
              <div className="col-md-6 col-sm-12 text-center">
                <h2 className="text-center jFontBold mt-3 mb-3">Status</h2>
                <Paper>
                  {instrumentData.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar>
                          <img
                            className="imageIcon"
                            src={useArticleImage}
                            alt=""
                            style={{ backgroundColor: "#F1F1F1" }}
                          />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={"Control# " + item.controlNo}
                        primaryTypographyProps={{
                          style: { fontWeight: "bold", fontSize: "30px" },
                        }}
                        secondary={item.itemName}
                        secondaryTypographyProps={{
                          style: {
                            fontWeight: "bold",
                            fontSize: "20px",
                            color: "black",
                          },
                        }}
                      ></ListItemText>
                      <ListItemText
                        secondary={"" + status}
                        secondaryTypographyProps={{
                          className: "form-control col-md-3",
                          style: {
                            justifyContent: "center",
                            display: "flex",
                            fontWeight: "bold",
                            color: "green",
                            position: "absolute",
                            left: "55%",
                            transform: "translateY(-100%)",
                          },
                        }}
                      ></ListItemText>
                      <Button
                        id="basic-button"
                        aria-controls=""
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ color: "#b22222" }}
                        onClick={() => removeItem(item)}
                      >
                        <DeleteIcon />
                      </Button>
                    </ListItem>
                  ))}
                </Paper>
                <button
                  type="button"
                  onClick={confirmUse}
                  className="btn btnColor btn-lg m-3"
                >
                  Confirm Use
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div>{open === true ? errorDialog() : ""}</div>
        <div> {ask === true ? questionDialog() : ""}</div>
      </div>
    </>
  );
}
