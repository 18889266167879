import React, { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { branchLocations } from "./RegisterBranch";
import SessionData from "../../../services/session-data";

export default function ModifyBranch(props: any) {
  const [open, setOpen] = useState(false);
  const [branchName, setBranchName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [contact, setContact] = useState<number>(0);
  const [rfidEnabled, setRfidEnabled] = useState("");
  let data: branchLocations = props.data;
  const [error, setError] = useState(false);
  let message = "Data Loading";
  const handleClose = () => {
    setOpen(false);
    props.parentCallback(message);
  };

  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      setBranchName(data.branch_name);
      setCity(data.city);
      setContact(data.branch_contact);
      setRfidEnabled(data.rfid_enabled);
      props.editOpen ? setOpen(true) : setOpen(false);
    }
  }, [data.branch_contact, data.branch_name, data.city, data.rfid_enabled, props.editOpen]);

  const EditBranch = async () => {
    const sd = new SessionData();
    try {
      const res = await axios.put(
        sd.getBaseURL() + "web-register/newbranch",
        {
          branch_id: data.branch_id,
          branch_name: branchName,
          city: city,
          contact: contact,
          rfid_enabled: Number(rfidEnabled),
        },
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
            "staff-auth": "Bearer " + sd.getStaffToken(),
          },
        }
      );
      if (res.status === 201) {
        message = "Branch Updated";
      }
    } catch (err: any) {
      message = "Something Went Wrong";
    } finally {
      setBranchName("");
      setCity("");
      setContact(0);
      setRfidEnabled("");
      handleClose();
    }
  };

  const showTheDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Edit Branch</DialogTitle>
          <DialogContent style={{ backgroundColor: "#eff9f0" }}>
            <DialogContentText>
              {/* showing articles in this load */}
              <form>
                <div className="form-group">
                  <label htmlFor="brnachName">Branch Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => setBranchName(e.target.value)}
                    value={branchName}
                    id="brnachName"
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    id="city"
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="contact">Contact</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => setContact(Number(e.target.value))}
                    value={Number(contact).toString()}
                    id="contact"
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="rfid-enabled">RFID Enable</label>
                  <select
                    className="form-control"
                    id="selectCategory"
                    onChange={(e) => setRfidEnabled(e.target.value)}
                    value={rfidEnabled}
                  >
                    <option value="">--Select--</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
                {error ? <div>Please fill all the fields</div> : ""}
              </form>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ backgroundColor: "#eff9f0" }}>
            <Button onClick={formValidation} color="primary" autoFocus>
              Submit
            </Button>
            <Button onClick={(handleClose)} style={{ color: "red" }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const formValidation = () => {
    if (branchName.length <= 0 || city.length <= 0 || data.branch_id <= 0) {
      setError(true);
      return;
    }
    EditBranch();
  };

  return <div>{open ? showTheDialog() : ""}</div>;
}
