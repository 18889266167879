/* eslint-disable */
import React, { useEffect, useState } from "react";
import SessionData from "../../services/session-data";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import { RepeatRounded } from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import { useStyles } from "../Styles/mui-styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import validator from "validator";
import SelectBranch from "../Utils/SelectBranch";
import getStaffList from "../Utils/FetchStaffList";

export type locData = {
  id: number;
  name: string;
  username: string;
  level: string;
  branch_name: string;
  flag: number;
  pin: string;
};

const sd = new SessionData();

export default function RegisterAdminUser() {
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPass, setRepeatPass] = useState("");
  const [username, setUsername] = useState<string>("");
  const [callEffect, setCallEffect] = useState<boolean>(false);
  const [pin, setPin] = useState<string>("");
  const [wristband, setWristband] = useState("");
  const [level, setLevel] = useState("");
  const [usernameError, setUserNameError] = useState("");
  const [pinError, setPinError] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dialog, setDialog] = useState("hidden");
  const [open, setOpen] = React.useState(false);
  const [dMessage, setDMessage] = React.useState("");
  const [passmsg, setPassMsg] = useState("");
  const [sendEmail, setSendEmail] = useState(false);
  const [staffData, setStaffData] = useState<any>([]);
  const [branchData, setBranchData] = useState<any>([]);
  const getStaffDetails = getStaffList();
  let getData: locData;
  const handleClose = () => {
    setOpen(false);
    setCallEffect(!callEffect);
  };

  // Setting up the branch Id
  let branchId: any = "";
  if (localStorage.getItem("roleId") === "1") {
    branchId = localStorage.getItem("branchLocation");
  } else {
    branchId = localStorage.getItem("branchId");
  }

  const getBranchLocations = () => {
    axios
      .get(sd.getBaseURL() + "web-standalone/get-branch", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("List empty");
        setBranchData(data.data);
      })
      .catch((err) => {});
  };
  const getStaff = () => {
    axios
      .get(sd.getBaseURL() + "staff", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sd.getClientToken(),
        },
      })
      .then((data) => {
        if (data.status !== 200) throw new Error("List empty");
        setStaffData(data.data.data);
      })
      .catch((err) => {});
  };
  //-------------------------
  useEffect(() => {
    getStaffDetails.request();
    getStaff();
    getBranchLocations();
  }, [callEffect]);

  const checkPassword = (value) => {
    setRepeatPass(value);
    if (value !== password) setPassMsg("The passwords don't match");
    else setPassMsg("");
  };

  // To handle the pin registered
  const handlePin = (epin) => {
    setPin(epin);
    if (epin.length < 4) setPinError("Pin must be 4 to 6 charachets long");
    else setPinError("");
  };
  //check if username is already registered
  const handleUserName = (userName) => {
    setUsername(userName.toString().trim());
    if (
      staffData.filter(
        (item) => item.username.toLowerCase() === userName.toLowerCase()
      ).length > 0
    ) {
      setUserNameError("username already in use");
    } else {
      setUserNameError("");
    }
  };
  const formValidation = () => {
    //everything is good
    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      username.length > 0 &&
      validator.isEmail(email) &&
      password.length >= 6 &&
      password === repeatPass &&
      pin!.toString().length >= 4 &&
      level!.toString().length > 0
    ) {
      //make a call
      registerUser();
    }
    //fields empty
    else if (
      email.length <= 0 ||
      password.length <= 0 ||
      RepeatRounded.length <= 0 ||
      firstName.length <= 0
    ) {
      setDMessage("Please fill all the fields");
      setOpen(true);
      return;
    } //password length
    else if (password.length < 6 || password.length > 30) {
      setDMessage("Password must be atlease 6 characters long");
      setOpen(true);
      return;
    } //email not valid
    else if (!validator.isEmail(email)) {
      setDMessage("Email id is not valid");
      setOpen(true);
      return;
    } else {
      setDMessage("Something went wrong, please try again later!");
      setOpen(true);
      return;
    }
  };

  const errorDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Register New User</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const registerUser = () => {
    const sd = new SessionData();
    setDialog("hidden");

    axios
      .post(
        sd.getBaseURL() + "user",
        {
          name: firstName.concat(" ", lastName),
          email: email,
          password: password,
          username: username,
          pin: pin,
          level: level,
          branch_id: branchId,
          wristband: wristband,
          sendEmail: sendEmail,
        },
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + sd.getClientToken(),
          },
        }
      )
      .then((data) => {
        if (data.status !== 201) {
          setDMessage("Registration Failed. Please check your fields!");
          setOpen(true);
          return;
        }
        setDMessage("New User Registered");
        setOpen(true);
      })
      .catch((err) => {
        setDMessage("Registration Failed. Please check your fields");
        setOpen(true);
      });
    //empty everything
    setEmail("");
    setFirstName("");
    setLastName("");
    setWristband("");
    setPassword("");
    setRepeatPass("");
    setUsername("");
    setPin("");
    setLevel("");
  };

  return (
    <>
      <div className="container jMarginTop50 jMarginBottom50 col-md-8">
        <h2
          className="jFontBold"
          style={{ textAlign: "center", margin: "20px" }}
        >
          Register User
        </h2>
        <Paper className="jPadding">
          <form>
            <div className="form-group">
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="name">
                    First Name <span className="require-field">*</span>
                  </label>
                  <input
                    placeholder="First Name"
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    id="name"
                  ></input>
                </div>
                <div className="col-md-6">
                  <label htmlFor="name">
                    Last Name <span className="require-field">*</span>
                  </label>
                  <input
                    placeholder="Last Name"
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    id="name"
                  ></input>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="email">
                    Email <span className="require-field">*</span>
                  </label>
                  <input
                    placeholder="Email"
                    type="email"
                    className="form-control"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    id="email"
                  ></input>
                </div>
                <div className="col-md-6">
                  <label htmlFor="username">
                    Username <span className="require-field">*</span>
                  </label>
                  <input
                    className="form-control"
                    id="username"
                    onChange={(e) => handleUserName(e.target.value)}
                    value={username}
                  />
                  <div
                    className={
                      usernameError.length > 0
                        ? "chip-list"
                        : "chip-list hide-me"
                    }
                  >
                    <Chip
                      color="secondary"
                      label={usernameError}
                      className={classes.chip}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="pin">
                    Pin <span className="require-field">*</span>
                  </label>
                  <input
                    className="form-control"
                    id="pin"
                    maxLength={6}
                    type="number"
                    onChange={(e) => handlePin(e.target.value)}
                    value={pin}
                  />
                  <div
                    className={
                      pinError.length > 0 ? "chip-list" : "chip-list hide-me"
                    }
                  >
                    <Chip
                      color="secondary"
                      label={pinError}
                      className={classes.chip}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="wristbandtag" className="form-label">
                    Wristband Tagid
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="wristbandtag"
                    onChange={(e) => setWristband(e.target.value)}
                    value={wristband}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="password">
                    Password <span className="require-field">*</span>
                  </label>
                  <input
                    placeholder="Password"
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    id="password"
                  ></input>
                </div>

                <div className="col-md-6">
                  <label htmlFor="repeatPassword">
                    Repeat Password <span className="require-field">*</span>
                  </label>
                  <input
                    placeholder="Repeat Password"
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => checkPassword(e.target.value)}
                    value={repeatPass}
                    id="repeatPassword"
                  ></input>
                  <div style={{ color: "red" }}>{passmsg}</div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="selectlevel">
                    User Level <span className="require-field">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="selectlevel"
                    onChange={(e) => setLevel(e.target.value)}
                    value={level}
                  >
                    <option value="0">-- Select User Level --</option>
                    <option value="3">Staff</option>
                    <option value="2">Supervisor</option>
                    {localStorage.getItem("roleId") === "1" ? (
                      <option value="1">Admin</option>
                    ) : (
                      ""
                    )}
                  </select>
                </div>
                {localStorage.getItem("roleId") === "1" ? (
                  <div className="col-md-6">
                    <label htmlFor="selectlevel">
                      Branch Location <span className="require-field">*</span>
                    </label>
                    <SelectBranch />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row">
                <div className="col-4">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value=""
                      id="sendEmail"
                      onChange={() => setSendEmail((prev) => !prev)}
                    ></input>
                    <label className="form-check-label" htmlFor="sendEmail">
                      Send Email
                    </label>
                  </div>
                </div>
              </div>
              <button
                type="button"
                onClick={formValidation}
                className="btn btnColor btn-lg"
              >
                Register
              </button>
            </div>
          </form>
          <div className="row">
            {/* <div className="col" >
                    <div style={myStyles} >Please fill autoclave name, cycle no, and bowie dick to register</div>
                </div> */}
          </div>
        </Paper>
      </div>
      {open === true ? errorDialog() : ""}
    </>
  );
}
